// Router.js
import React, { useState } from 'react';
import { BrowserRouter, Routes, Route,Navigate } from 'react-router-dom';
import Layout from '../components/Layout';
import Home from '../pages/Home';
import Privacy from '../pages/Privacy';
import Security from '../pages/Security';
import Terms from '../pages/Terms';
import ProductsIclas from '../pages/ProductsIclas';
import ProductsVclas from '../pages/ProductsVclas';
import ProductsVclasUnderConstruction from '../pages/ProductsVclasUnderConstruction';
import TechnologyPfca from '../pages/TechnologyPfca';
import TechnologyUltc from '../pages/TechnologyUltc';
import ClinicalEvidenceStudies from '../pages/ClinicalEvidenceStudies';
import ClinicalEvidencePapers from '../pages/ClinicalEvidencePapers';
import ClinicalEvidencePresentations from '../pages/ClinicalEvidencePresentations';
import PressReleases from '../pages/PressReleases';
import PressReleasesDetails from '../pages/PressReleasesDetails';
import Events from '../pages/Events';
import About from '../pages/About';
import Careers from '../pages/Careers';
import CareersDetails from '../pages/CareersDetails';
import Patients from '../pages/Patients';
import SignIn from '../pages/SignIn';
import Register from '../pages/Register';
import ResetPassword from '../pages/ResetPassword';
import Apply from '../pages/Apply';
import MarketingMaterials from '../pages/MarketingMaterials';
import Contact from '../pages/Contact';
import UserProfile from '../pages/UserProfile';
import MarketingVClass from '../pages/MarketingVClas';
import Investor from '../pages/Investor';
import Faq from '../pages/Faq';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import CookieConsent from 'react-cookie-consent';
import Modal from '../pages/Modal';
import SettingsModal from '../pages/SettingsModal';

const AppLayout = ({ showModal, showSettingsModal, handleCloseModal, handleSettingsClick, handleCloseSettings, handleSaveSettings }) => (
  <Layout
    showModal={showModal}
    showSettingsModal={showSettingsModal}
    handleCloseModal={handleCloseModal}
    handleSettingsClick={handleSettingsClick}
    handleCloseSettings={handleCloseSettings}
    handleSaveSettings={handleSaveSettings}
  />
);

const Router = ({ currentUser }) => {
  const accessToken = localStorage.getItem('accessToken');
  const EU_URL = process.env.REACT_APP_LIVE_EU_URL;
  let region = window.location.href.includes(EU_URL) ? 'EU' : 'US';

  const [showModal, setShowModal] = useState(false); // Modal initially not shown
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  // Logic to handle modal visibility on route change
  const handleRouteChange = () => {
    setShowModal(true); // Show modal on every route change
  };

  return (
    <BrowserRouter>
      {/* {region === 'EU' && (
        <CookieConsent
          enableDeclineButton
          declineButtonText='Opt-Out'
          buttonText='Accept All'
          buttonStyle={{ backgroundColor: '#167ec5', color: '#ffffff' }}
          declineButtonStyle={{
            backgroundColor: 'transparent',
            color: '#ffffff',
            border: '1px solid #ffffff',
          }}
        >
          Adagio Medical values your privacy. We use cookies to enhance your
          website experience. Some cookies are necessary to run the website,
          others are optional: performance cookies show us how you use our
          website and features; functional cookies remember your preferences
          and advertising cookies help us share relevant content.
        </CookieConsent>
      )} */}
      <Routes>
				<Route path='/' element={<Layout />}>
					<Route index element={<Home />} />
					<Route path='privacy-policy' element={<Privacy />} />
					<Route path='product-security' element={<Security />} />
					<Route path='terms-of-use' element={<Terms />} />
					<Route path='products-iclas' element={<ProductsIclas />} />
					<Route path='product-vclas-under-construction' element={<ProductsVclasUnderConstruction />} />
					<Route path='products-vclas' element={<ProductsVclas />} />
					<Route path='technology-ultc' element={<TechnologyUltc />} />
					<Route path='technology-pfca' element={<TechnologyPfca />} />
					<Route
						path='clinical-evidence-studies'
						element={<ClinicalEvidenceStudies />}
					/>
					<Route
						path='clinical-evidence-papers'
						element={<ClinicalEvidencePapers />}
					/>
					<Route
						path='clinical-evidence-presentations'
						element={<ClinicalEvidencePresentations />}
					/>
					<Route path='marketing-iclas' element={<MarketingMaterials />} />
					<Route path='marketing-vclas' element={<MarketingVClass />} />
					<Route path='press-releases'>
						<Route index element={<PressReleases />} />
						<Route path=':id' element={<PressReleasesDetails />} />
					</Route>
					<Route path='events'>
						<Route index element={<Events region='US' />} />
					</Route>
					<Route path='about' element={<About />} />
					<Route path='investor' element={<Investor/>} />
					<Route path='corporate' element={<Investor/>} />
					<Route path='sec-filing' element={<Investor/>} />
					<Route path='events-presentations' element={<Investor/>} />
					<Route path='faq' element={<Faq />} />
					<Route path='careers'>
						<Route index element={<Careers />} />
						<Route path=':id' element={<CareersDetails />} />
					</Route>
					<Route path='patients' element={<Patients />} />
					<Route path='contact' element={<Contact />} />
					<Route path='sign-in' element={<SignIn />} />
					<Route path='apply' element={<Apply />} />
					<Route path='register' element={<Register />} />
					<Route path='reset-password' element={<ResetPassword />} />
					<Route
						path='your-profile'
						element={accessToken ? <UserProfile /> : <Navigate to='/sign-in' />}
					/>
				</Route>
			</Routes>
    </BrowserRouter>
  );
};

export default Router;
