import { useEffect, useState } from "react";

const HeroTwo = ({ data, pathname, selectedHeroIndex }) => {
	const currentUrl = window.location.host;
	const [region, setRegion] = useState("");

	let heroData;
	if (pathname === '/corporate') {
			heroData = data?.find(item => item.component_type === "BOTH") 
								|| data?.find(item => (item.region === region || item.region === "BOTH") && item.component_type === "Corporate Governance");
	} else if (pathname === '/sec-filing') {
			heroData = data?.find(item => item.component_type === "BOTH") 
								|| data?.find(item => (item.region === region || item.region === "BOTH") && item.component_type === "SEC Filing");
	} else if (pathname === '/events-presentations') {
			heroData = data?.find(item => item.component_type === "BOTH") 
								|| data?.find(item => (item.region === region || item.region === "BOTH") && item.component_type === "Investor Presentation");
	} else {
			heroData = data[0]; // Default to the first item or any other logic
	}

	useEffect(() => {
		if (currentUrl.includes("localhost:3300") 
			|| currentUrl.includes("aidandang.firebase") 
			|| currentUrl.includes("us.adagiomedical.com")
			|| currentUrl.includes("adagio-us-dev.web.app")
		) {
			setRegion("US");
			} else {
				setRegion("UK");
			}
	}, [])

	return (
		<section id='hero'>
			<div className='relative'>
				<div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
					<div className='relative sm:overflow-hidden'>
						<div className='absolute inset-0'>
							<img
								className='h-full w-full object-cover'
								src={heroData?.url}
								alt='People working on investor resources'
							/>
							<div className='absolute inset-0' />
						</div>
						<div className='relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8'>
							<h1 className='text-center text-3xl font-medium tracking-tight sm:text-4xl lg:text-5xl'>
								<span className='block text-white'>{heroData?.title}</span>
								<span className='block text-white text-xl sm:text-2xl lg:text-3xl'>
									{heroData?.sub_title}
								</span>
							</h1>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HeroTwo;
