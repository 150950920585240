import React, { useState, useEffect } from 'react';
import Hero from '../components/hero';
import SecondSection from '../components/second-section';
import OurMissionSection from '../components/our-mission-section';
import ManagementTeamSection from '../components/management-team-section';
import { getPage } from '../helpers/api-utils';
import BoardOfDirectorSection from '../components/board-of-director-section';
import { useLocation } from 'react-router-dom';


export default function About() {
	const [data, setData] = useState(null);
	const location = useLocation();

	useEffect(() => {
		const apiPath = 'about-pages';
		const resObjName = 'aboutPages';
		getPage(apiPath, resObjName, setData);
	}, []);

	useEffect(() => {
		if (data) {
			const hashValue = location.hash.substring(1);
			const targetElement = document.getElementById(hashValue);
			if (hashValue && targetElement) {
				window.scrollTo({
					top: targetElement.offsetTop,
					behavior: 'smooth',
				});
			}
		}
  }, [location, data]);

	return (
		<>
      <Hero data={data?.hero} />
      <SecondSection data={data?.second} />
      <OurMissionSection data={data?.ourMission} />
      <ManagementTeamSection data={data?.managementTeam} />
      <BoardOfDirectorSection data={data?.boardOfDirectors} />
			{/* {data ? (
				<>
					<Hero data={data.hero} />
					<SecondSection data={data.second} />
					<OurMissionSection data={data.ourMission} />
					<ManagementTeamSection data={data.managementTeam} />
					<BoardOfDirectorSection data={data.boardOfDirectors} />
				</>
			) : (
				<Spinner />
			)} */}
		</>
	);
}
