import React, { useState, useEffect } from 'react';
import {
	LinkIcon,
} from '@heroicons/react/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';
import { getMaterialVclas } from '../../helpers/api-utils';
import { useSelector } from 'react-redux';


const MaterialVclasList = ({ parent }) => {
	const user = useSelector((state) => state.auth.currentUser);

	const [currentMaterialVclas, setCurrentMaterialVclas] = useState({
		materials: null,
		current: 0,
		pages: null,
		numberOfPages: 0,
	});

	const checkPrivate = (privateMaterial) => {
		if (privateMaterial) {
			if (user && user.uid) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	};

	useEffect(() => {
		async function fetchData() {
			const response = await getMaterialVclas(parent._id);

			setCurrentMaterialVclas((prevState) => ({
				...prevState,
				materialVclases: response.materialVclases,
			}));
		}

		fetchData();
	}, []);

	return (
		<>
			{currentMaterialVclas.materialVclases && currentMaterialVclas.materialVclases.length > 0 && (
				<>
					<h2 className='mt-8 flex flex-col items-center md:flex-row md:justify-center font-semibold'>
						<a
							href='/'
							onClick={(e) => {
								e.preventDefault();
							}}
							className={'text-primary-blue block'}
						>
							{parent.technology}
						</a>
						<span className='md:block mx-3 hidden'>{'|'}</span>
						<a
							href='/'
							onClick={(e) => {
								e.preventDefault();
							}}
							className={'text-primary-blue block mt-1 md:mt-0'}
						>
							{parent.condition}
						</a>
					</h2>
					<div className='mt-3'>
						<div className='relative'>
							<div className='absolute inset-0 flex items-center'>
								<div className='w-full border-t border-gray-300' />
							</div>
							<div className='relative flex justify-center text-sm'>
								<span className='px-2 bg-gray-100 text-gray-400 text-lg'>
									<FontAwesomeIcon icon={faSnowflake} />
								</span>
							</div>
						</div>
					</div>
					<div className='-mx-px border-l border-gray-200 grid grid-cols-2 sm:mx-0 md:grid-cols-3 lg:grid-cols-4 mt-3 border-t'>
						{currentMaterialVclas.materialVclases &&
							currentMaterialVclas.materialVclases.map((material) => (
								<div
									key={material._id}
									className='group relative p-4 border-r border-gray-200 sm:p-6 border-b'
								>
									<div
										className='block rounded-lg overflow-hidden bg-white group-hover:opacity-75'
									>
										<img
											src={material.imageUrl}
											alt={material.alt}
											className='w-full h-full object-center object-cover'
										/>
									</div>
									<div className='pt-6 pb-2'>
										<p className='text-sm font-semibold text-gray-900 text-center mb-2'>
											{material.title}
										</p>
										<p className='text-sm font-semibold text-gray-900 text-center mb-2'>
											{material.subtitle}
										</p>
										<div className='w-full flex justify-center px-4 h-auto text-primary-blue text-sm'>
											<LinkIcon className='h-5 w-5 text-gray-800' />{' '}
											<a
												href={
													material.private === true && user !== 'advance'
														? '/register'
														: material.url
												}
												className='ml-2'
												target='_blank' rel="noreferrer"
											>
												{material.filename}
											</a>
										</div>
									</div>
								</div>
							))}
					</div>
				</>
			)}
		</>
	);
};

export default MaterialVclasList;
