import React, { useState, useEffect } from 'react';
import Hero from '../components/hero';
import SecondMaterialsSection from '../components/second-materials-section';
import { getPage } from '../helpers/api-utils';

const region = process.env.REACT_APP_REGION;

export default function MarketingMaterials() {
	let search = '';
	const [data, setData] = useState(null);

	useEffect(() => {
		if (window.location.href.includes(region)) {
			search = 'EU';
		} else {
			search = 'US';
		}
		const apiPath = 'marketing-materials-pages';
		const resObjName = 'materialsPages';
		getPage(apiPath, resObjName, setData, search);
	}, []);
	return (
		<>
			{data && (
				<>
					<Hero data={data.hero} />
					<SecondMaterialsSection />
				</>
			)}
		</>
	);
}
