import React, { useState, useEffect } from 'react';
import {
	ArrowNarrowLeftIcon,
	ArrowNarrowRightIcon,
} from '@heroicons/react/solid';
import { getPosts } from '../../helpers/api-utils';

const currentClass =
	'border-secondary-blue text-primary-blue border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium';
const normalClass =
	'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium';

const EventsList = ({ category }) => {
	const [currentPosts, setCurrentPosts] = useState({
		posts: null,
		current: 0,
		pages: null,
		numberOfPages: 0,
	});

	const onPageChange = async (index) => {
		const response = await getPosts(index + 1, 'event', category);

		setCurrentPosts((prevState) => ({
			...prevState,
			posts: response.posts,
			current: index,
		}));
	};

	useEffect(() => {
		async function fetchData() {
			const response = await getPosts(1, 'event', category);
			const numberOfPages = Math.ceil(response.count / 5);
			const pages = new Array(numberOfPages).fill('*');

			setCurrentPosts((prevState) => ({
				...prevState,
				posts: response.posts,
				pages: pages,
				numberOfPages: numberOfPages,
			}));
		}

		fetchData();
	}, [category]);

	return (
		<div className='mt-3'>
			<div className='divide-y divide-gray-200'>
				{currentPosts.posts &&
					currentPosts.posts.map((post) => (
						<div key={post._id} className='flex py-6 sm:py-10'>
							<div className='flex-shrink-0'>
								<img
									src={post.imageUrl}
									alt="Front of men's Basic Tee in sienna."
									className='w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48'
								/>
							</div>

							<div className='ml-4 flex-1 flex flex-col sm:ml-6 lg:ml-8'>
								<h4 className='font-semibold'>{post.title}</h4>
								<div className='line-clamp-3 my-3'>
									<div
										dangerouslySetInnerHTML={{
											__html: post.teaser,
										}}
									></div>
								</div>
								<a
									href={`/press-releases/${post._id}`}
									alt={post.title}
									className='block text-primary-blue font-medium'
								>
									...READ MORE
								</a>
							</div>
						</div>
					))}
			</div>

			<nav className='border-t border-gray-200 px-4 flex items-center justify-between sm:px-0'>
				<div className='-mt-px w-0 flex-1 flex'>
					<a
						href='#'
						onClick={(e) => {
							e.preventDefault();
							if (currentPosts.current > 0) {
								onPageChange(currentPosts.current - 1);
							}
						}}
						className='border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'
					>
						<ArrowNarrowLeftIcon
							className='mr-3 h-5 w-5 text-gray-400'
							aria-hidden='true'
						/>
						Previous
					</a>
				</div>
				<div className='hidden md:-mt-px md:flex'>
					{currentPosts.pages &&
						currentPosts.pages.map((el, index) => (
							<a
								key={index}
								href='#'
								onClick={(e) => {
									e.preventDefault();
									onPageChange(index);
								}}
								className={
									index === currentPosts.current ? currentClass : normalClass
								}
							>
								{index + 1}
							</a>
						))}
				</div>
				<div className='-mt-px w-0 flex-1 flex justify-end'>
					<a
						href='#'
						onClick={(e) => {
							e.preventDefault();
							if (currentPosts.current < currentPosts.numberOfPages - 1) {
								onPageChange(currentPosts.current + 1);
							}
						}}
						className='border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'
					>
						Next
						<ArrowNarrowRightIcon
							className='ml-3 h-5 w-5 text-gray-400'
							aria-hidden='true'
						/>
					</a>
				</div>
			</nav>
		</div>
	);
};

export default EventsList;
