/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';
import {
	ArrowNarrowLeftIcon,
	ArrowNarrowRightIcon,
} from '@heroicons/react/solid';
import { getPositions } from '../../helpers/api-utils';

const currentClass =
	'border-secondary-blue text-primary-blue border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium';
const normalClass =
	'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium';

const SecondCareersSection = ({ data, positions }) => {
	const [currentPositions, setCurrentPositions] = useState({
		positions: positions,
		current: 0,
		pages: null,
		numberOfPages: 0,
	});

	const onPageChange = async (index) => {
		const data = await getPositions(index + 1);

		setCurrentPositions((prevState) => ({
			...prevState,
			positions: data.positions,
			current: index,
		}));
	};

	useEffect(() => {
		async function fetchData() {
			const response = await getPositions(1);
			const numberOfPages = Math.ceil(response.count / 30);
			const pages = new Array(numberOfPages).fill('*');

			setCurrentPositions((prevState) => ({
				...prevState,
				positions: response.positions,
				pages: pages,
				numberOfPages: numberOfPages,
			}));
		}

		fetchData();
	}, []);

	return (
		<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-5'>
			<div
				style={{ backgroundImage: `url(\'${data.bgImgUrl}\')` }}
				className='w-full flex justify-center py-10 px-12 sm:px-20 md:px-28 lg:px-36'
			>
				<div className='min-w-full bg-white py-8 px-4 shadow sm:px-10'>
					<h2 className='mt-3 flex flex-col items-center text-gray-400 md:flex-row md:justify-center font-semibold'>
						WE ARE LOOKING FOR
					</h2>
					<div className='mt-3'>
						<div className='relative'>
							<div className='absolute inset-0 flex items-center'>
								<div className='w-full border-t border-gray-300' />
							</div>
							<div className='relative flex justify-center text-sm'>
								<span className='px-2 bg-white text-gray-400 text-lg'>
									<FontAwesomeIcon icon={faSnowflake} />
								</span>
							</div>
						</div>
					</div>
					<div className='mt-3'>
						<div className='divide-y divide-gray-200'>
							{currentPositions.positions &&
								currentPositions.positions.map((position) => (
									<div key={position._id} className='py-5 w-full text-center'>
										<a
											href={`/careers/${position._id}`}
											alt=''
											className='text-primary-blue'
										>
											<h4 className='font-semibold'>{position.title}</h4>
										</a>
									</div>
								))}
						</div>

						<nav className='border-t border-gray-200 px-4 flex items-center justify-between sm:px-0'>
							<div className='-mt-px w-0 flex-1 flex'>
								<a
									href='#'
									onClick={(e) => {
										e.preventDefault();
										if (currentPositions.current > 0) {
											onPageChange(currentPositions.current - 1);
										}
									}}
									className='border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'
								>
									<ArrowNarrowLeftIcon
										className='mr-3 h-5 w-5 text-gray-400'
										aria-hidden='true'
									/>
									Previous
								</a>
							</div>
							<div className='hidden md:-mt-px md:flex'>
								{currentPositions.pages &&
									currentPositions.pages.map((el, index) => (
										<a
											key={index}
											href='#'
											onClick={(e) => {
												e.preventDefault();
												onPageChange(index);
											}}
											className={
												index === currentPositions.current
													? currentClass
													: normalClass
											}
										>
											{index + 1}
										</a>
									))}
							</div>
							<div className='-mt-px w-0 flex-1 flex justify-end'>
								<a
									href='#'
									onClick={(e) => {
										e.preventDefault();
										if (
											currentPositions.current <
											currentPositions.numberOfPages - 1
										) {
											onPageChange(currentPositions.current + 1);
										}
									}}
									className='border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'
								>
									Next
									<ArrowNarrowRightIcon
										className='ml-3 h-5 w-5 text-gray-400'
										aria-hidden='true'
									/>
								</a>
							</div>
						</nav>
					</div>
				</div>
			</div>
		</section>
	);
};

export default SecondCareersSection;
