import React from 'react';
import FormSubmit from '../FormSubmit';
import Fields from './Fields';
import { sendContact } from '../../../store/slices/authSlice';

const getReqBody = (values) => {
	return {
		firstName: values.firstName,
		lastName: values.lastName,
		title: values.title || 'n/a',
		organization: values.organization || 'n/a',
		email: values.email,
		phone: values.phone || 'n/a',
		message: values.message,
	};
};
const buttonLabel = 'Submit';
const redirectPath = '';

const ContactForm = ({ setRedirectPath }) => {
	return (
		<FormSubmit
			component={Fields}
			getReqBody={getReqBody}
			submitAction={sendContact}
			buttonLabel={buttonLabel}
			redirectPath={redirectPath}
			setRedirectPath={setRedirectPath}
		/>
	);
};

export default ContactForm;
