import React, { useState, useEffect } from 'react';
import PaperList from './PaperList';

import { getCategories } from '../../helpers/api-utils';

export default function SecondStudiesSection() {
	const [currentCategories, setCurrentCategories] = useState({
		categories: null,
		current: 0,
		pages: null,
		numberOfPages: 0,
	});

	useEffect(() => {
		async function fetchData() {
			const response = await getCategories('papers-us');
			const numberOfPages = Math.ceil(response.count / 5);
			const pages = new Array(numberOfPages).fill('*');

			setCurrentCategories((prevState) => ({
				...prevState,
				categories: response.categories,
				pages: pages,
				numberOfPages: numberOfPages,
			}));
		}

		fetchData();
	}, []);

	return (
		<>
			<section
				aria-labelledby='products-heading'
				className='max-w-7xl mx-auto overflow-hidden sm:px-6 lg:px-8 mt-5'
			>
				<div className='bg-gray-100 -mt-5'>
					{currentCategories.categories &&
						currentCategories.categories.map((parent, idx) => (
							<PaperList parent={parent} key={idx} />
						))}
				</div>
			</section>
		</>
	);
}
