import React from 'react';
import { Form as ReduxForm } from 'react-final-form';
import { connect } from 'react-redux';

import { Form, ButtonSet, Button, Divider } from './inputs';
import SubmissionError from './SubmissionError';

class FormSubmit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			path: '',
			errorMessage: '',
			isPending: false,
		};
	}

	onSubmitCallback = (errorMessage, state) => {
		this.setState((prevState) => ({
			...prevState,
			isPending: false,
		}));

		if (errorMessage) {
			this.setState((prevState) => ({
				...prevState,
				errorMessage,
			}));
		} else {
			if (this.props.setRedirectPath) {
				this.props.setRedirectPath(this.props.redirectPath || state);
			}
		}
	};

	closeSubmissionError = () => {
		this.setState((prevState) => ({
			...prevState,
			errorMessage: '',
		}));
	};

	onSubmit = (values) => {
		this.setState((prevState) => ({
			...prevState,
			isPending: true,
		}));

		const reqBody = { ...this.props.getReqBody(values, this.props.file) };

		this.props.dispatch(
			this.props.submitAction(reqBody, this.onSubmitCallback, values)
		);
	};

	render() {
		const { component: Fields, buttonLabel } = this.props;

		return (
			<ReduxForm
				onSubmit={(values) => {
					this.onSubmit(values);
				}}
				initialValues={this.props.initialValues}
				render={({ handleSubmit, submitting, values }) => (
					<Form onSubmit={handleSubmit}>
						{this.state.errorMessage && (
							<SubmissionError
								close={this.closeSubmissionError}
								errorMessage={this.state.errorMessage}
							/>
						)}

						<Divider>{<Fields values={values} />}</Divider>

						<ButtonSet>
							{this.state.isPending ? (
								<button
									type='button'
									disabled
									className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400'
								>
									<svg
										className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
									>
										<circle
											className='opacity-25'
											cx='12'
											cy='12'
											r='10'
											stroke='currentColor'
											strokeWidth='4'
										></circle>
										<path
											className='opacity-75'
											fill='currentColor'
											d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
										></path>
									</svg>
									Processing...
								</button>
							) : (
								<Button
									type='submit'
									label={buttonLabel}
									disabled={submitting}
								/>
							)}
						</ButtonSet>
					</Form>
				)}
			/>
		);
	}
}

export default connect()(FormSubmit);
