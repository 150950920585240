import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function TwoCellSection({ data }) {
	return (
		<>
			<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-5'>
				<div className='grid grid-cols-1 gap-0.5 md:grid-cols-2 bg-white'>
					{data.map((cell) => (
						<div key={uuidv4()} className='col-span-1 bg-gray-200'>
							<a
								href={cell.readmorePath}
								className='relative w-full h-60 p-6 flex flex-col overflow-hidden hover:opacity-75 xl:w-auto'
							>
								<span aria-hidden='true' className='absolute inset-0'>
									<img
										src={cell.imageUrl}
										alt={cell.alt}
										className='w-full h-full object-center object-cover'
									/>
								</span>
								<span
									aria-hidden='true'
									className='absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50'
								/>
								<span className='relative left-10 right-10 top-28 text-xl font-bold text-white w-full'>
									{cell.title} <br />
									<span className='text-sm'>see more {'>'}</span>
								</span>
							</a>

							<div className='p-6 sm:px-6 lg:px-8'>
								<div
									dangerouslySetInnerHTML={{
										__html: cell.body,
									}}
								></div>
							</div>
						</div>
					))}
				</div>
			</section>
		</>
	);
}
