/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { getInvestorPresentations } from '../../helpers/api-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';

const InvestorComponent = () => {
  const apiPath = 'investor-presentations';
  const resObjName = 'investorPresentations';
  const currentUrl = window.location.host;

  const [tab, setTab] = useState('Upcoming');
  const [data, setData] = useState(null);
  const [region, setRegion] = useState("US");
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const period = hours < 12 ? 'AM' : 'PM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    return `${formattedHours}:${minutes.toString().padStart(2, '0')}${period}`;
  };

  useEffect(() => {
    getInvestorPresentations(apiPath, resObjName, setData, tab, region)
  }, [tab])

  useEffect(() => {
    let search = ""
        if (currentUrl.includes("localhost:3300") 
			|| currentUrl.includes("aidandang.firebase") 
			|| currentUrl.includes("us.adagiomedical.com")
			|| currentUrl.includes("adagio-us-dev.web.app")
		) {
      search = "US";
			setRegion("US");
		} else {
      search = "UK";
			setRegion("UK");
		}
    getInvestorPresentations(apiPath, resObjName, setData, tab, search)
  }, []);

  console.log("presentation: ", data);

  return (
    <div className='flex-col'>
      <div className='min-w-full bg-white py-8 px-4 sm:px-10'>
        <h2 className='mt-3 flex flex-col items-center md:flex-row md:justify-center font-semibold'>
          <a
            href='#'
            onClick={(e) => {
              e.preventDefault();
              setTab('Upcoming');
            }}
            className={`${
              tab === 'Upcoming' ? 'text-gray-400 underline' : 'text-primary-blue'
            } block`}
          >
            UPCOMING
          </a>
          <span className='md:block mx-3 hidden'>{'|'}</span>
          <a
            href='#'
            onClick={(e) => {
              e.preventDefault();
              setTab('Past');
            }}
            className={`${
              tab === 'Past' ? 'text-gray-400 underline' : 'text-primary-blue'
            } block mt-1 md:mt-0`}
          >
            PAST
          </a>
        </h2>
      </div>
      <div className='mt-1'>
        <div className='relative'>
          <div className='absolute inset-0 flex items-center'>
            <div className='w-full border-t border-gray-300' />
          </div>
          <div className='relative flex justify-center text-sm'>
            <span className='px-2 bg-white text-gray-400 text-lg'>
              <FontAwesomeIcon icon={faSnowflake} />
            </span>
          </div>
        </div>
      </div>
      <div className='text-center font-bold text-2xl py-8'>
        <p>{tab === 'Upcoming' ? 'Upcoming Events' : 'Past Events'}</p>
      </div>
      {data &&
        data.map((presentation) => (
          <div>
            <div
                key={presentation._id}
                className="flex max-full justify-start bg-white dark:bg-white mb-4 p-2"
                style={{ flex: '1 0 75%' }}
            >
                {/* Text */}
                <div className='w-full md:w-1/3 flex flex-col justify-between'>
                    <div className="p-2 flex-grow flex flex-col justify-between">
                        <div>
                            <h5 className="mb-2 text-xl tracking-tight text-gray-900 dark:text-gray-900">
                                {presentation.title}
                            </h5>
                        </div>
                        <p className="font-normal text-gray-700 dark:text-gray-700 flex-grow">
                            {formatDate(presentation.date)}
                        </p>
                        <p className="font-normal text-gray-700 dark:text-gray-700 flex-grow mb-4">
                            {presentation.event_time ? formatTime(presentation.event_time) + ' EST' : ''}
                        </p>
                        <div className='flex flex-col justify-start space-y-2'>
                          {presentation.pptMime === 'application/pdf' && (
                            <a
                              href={presentation.pptUrl}
                              target='_blank'
                              className="flex items-center text-sky-700"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-file-pdf" className='mr-2' style={{color: "#b12525",}} />
                              <p>View presentation</p>
                            </a>
                          )}
                          {presentation.webcast_link && (
                            <a
                              href={presentation.webcast_link}
                              target='_blank'
                              className="flex items-center text-sky-700"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-volume-low" className='mr-2' style={{color: 'rgb(0, 0, 0)',}} />
                              <p>Listen to webcast</p>
                            </a>
                          )}
                          {presentation.videoMime === 'video/mp4' && (
                            <a
                              href={presentation.videoUrl}
                              target='_blank'
                              className="flex items-center text-sky-700"
                            >
                              <FontAwesomeIcon icon="fa fa-circle-play fa-xs" className="mr-2" style={{ color: 'rgb(0, 0, 0)' }} />
                              <p>View video</p>
                            </a>
                          )}
                        </div>
                    </div>
                </div>
            </div>
            <hr className='w-1/2 h-0.5 border-t-2 bg-black dark:bg-white/10' />
          </div>
        ))}
    </div>
  );
};

export default InvestorComponent;
