import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Root from './store';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
	gtmId: 'GTM-KMPMMQZ',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Root>
		<App />
	</Root>
);
