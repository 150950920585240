import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';

const SecondProductsSection = ({ data }) => {
	const [show, setShow] = useState(false);
	return (
		<>
			<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-5'>
				<div
					style={{ backgroundImage: `url(\'${data.bgImgUrl}\')` }}
					className='w-full flex justify-center py-10 px-12 sm:px-20 md:px-28 lg:px-36'
				>
					<div className='min-w-full bg-white py-8 px-4 shadow sm:px-10'>
						<h2 className='text-center font-semibold text-primary-blue'>
							{data.title}
						</h2>
						<div className='mt-3'>
							<div className='relative'>
								<div className='absolute inset-0 flex items-center'>
									<div className='w-full border-t border-gray-300' />
								</div>
								<div className='relative flex justify-center text-sm'>
									<span className='px-2 bg-white text-gray-400 text-lg'>
										<FontAwesomeIcon icon={faSnowflake} />
									</span>
								</div>
							</div>
						</div>
						<div className='mt-3'>
							<div
								dangerouslySetInnerHTML={{
									__html: data.body,
								}}
							></div>
						</div>
						<p className='mt-3 font-bold'>{data.term}</p>
						<p className='mt-3 font-medium text-primary-blue'>
							<a
								href='#'
								onClick={(e) => {
									e.preventDefault();
									setShow(!show);
								}}
							>
								{!show ? 'Show more...' : 'Show less...'}
							</a>
						</p>
					</div>
				</div>
			</section>
			{show && (
				<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 pb-5'>
					<div className='w-full flex flex-col items-center justify-center py-10 px-12 sm:px-20 md:px-28 lg:px-36'>
						<div className='mt-5'>
							<img src={data.detailsImgUrl} alt='' />
						</div>
					</div>
				</section>
			)}
		</>
	);
};

export default SecondProductsSection;
