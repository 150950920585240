import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FormTitle from '../lib/form/FormTitle';
import ResetPasswordForm from '../lib/form/reset-password';

const initialState = '';

const ResetPassword = () => {
	const [redirectPath, setRedirectPath] = useState(initialState);

	return (
		<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
			{redirectPath ? (
				<div>
					<h3 className='text-lg leading-6 font-medium text-gray-900'>
						Password reset request successful
					</h3>
					<p className='my-5 text-sm'>
						You should receive your reset link soon at{' '}
						<span className='font-medium'>{redirectPath}</span> or by text if
						you opted in for text notifications.
					</p>
					<h4 className='text-sm leading-6 font-medium text-gray-900'>
						Can't find the email?
					</h4>
					<p className='my-5 text-sm'>
						If the email address you entered does not match, you will not
						receive an email. Check that the email address above is correct.
						<br />
						You should also check your spam folder.
					</p>
					<h4 className='text-sm leading-6 font-medium text-gray-900'>
						Already changed your password?
					</h4>
					<p className='my-5 text-sm'>
						Please{' '}
						<Link
							to={'/sign-in'}
							className='font-medium text-primary-blue hover:text-secondary-blue'
						>
							sign in
						</Link>
						.
					</p>
				</div>
			) : (
				<>
					<FormTitle title='Reset Password' />
					<ResetPasswordForm setRedirectPath={setRedirectPath} />
				</>
			)}
		</main>
	);
};

export default ResetPassword;
