import React from 'react';
import { Subtitle, TitleSet } from './inputs';
import RouterLink from '../../components/common';

const FormTitle = ({ title }) => {
	return (
		<div className="flex items-center justify-start pt-10 sm:pt-12 lg:pt-4 pb-2 sm:pb-3 lg:pb-4">
			<h1 className='text-3xl font-semibold leading-6 text-gray-500 mr-4'>
				{title}
			</h1>
			<TitleSet>
				<Subtitle>
					Already a member? <RouterLink path='/sign-in'>Sign in</RouterLink>
				</Subtitle>
			</TitleSet>
		</div>
	);
};

export default FormTitle;
