import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';

import { getPageId } from '../helpers/api-utils';

let EU_URL = process.env.REACT_APP_LIVE_EU_URL;
if (process.env.NODE_ENV === 'development') {
	EU_URL = process.env.REACT_APP_LOCAL_EU_URL;
}

export default function Terms() {
	let pathname = 'terms-pages/62fbf9b39052b43cc2d81ca0';
	if (window.location.href.includes(EU_URL)) {
		pathname = 'terms-pages/62fbf9dd9052b43cc2d81cf2';
	}

	const [data, setData] = useState(null);

	useEffect(() => {
		const resObjName = 'termsPage';
		getPageId(pathname, resObjName, setData);
	}, []);

	return (
		<>
			<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
				<h1 className='text-primary-blue text-3xl pb-5'>
					{data && data.title}
				</h1>
				<div
					className='ql-editor'
					dangerouslySetInnerHTML={{
						__html: data && data.body,
					}}
				></div>
			</main>
		</>
	);
}
