import React from 'react';
import { XCircleIcon } from '@heroicons/react/solid';

const SubmissionError = ({ close, errorMessage }) => {
	return (
		<div className='rounded-md bg-red-50 p-4 mt-8'>
			<div className='flex'>
				<div className='flex-shrink-0'>
					<XCircleIcon
						onClick={(e) => {
							e.preventDefault();
							close();
						}}
						className='h-5 w-5 text-red-400 cursor-pointer'
						aria-hidden='true'
					/>
				</div>
				<div className='ml-3'>
					<h3 className='text-sm font-medium text-red-800'>
						There were error(s) with your submission
					</h3>
					<div className='mt-2 text-sm text-red-700'>
						<ul className='list-disc pl-5 space-y-1'>
							<li>{errorMessage}</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SubmissionError;
