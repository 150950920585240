import { auth } from '../firebase';
import axios from 'axios';

let BASE_API_URL = process.env.REACT_APP_LIVE_API_URL;

if (process.env.NODE_ENV === 'development') {
	BASE_API_URL = process.env.REACT_APP_LOCAL_API_URL;
}

export async function getPage(apiPath, resObjName, setData, region) {
	try {
		const res = await axios.get(
			BASE_API_URL + apiPath + '?status=active' + `&region=${region}`
		);

		setData(res.data[resObjName][0]);
	} catch (err) {
		console.log(err);
	}
}

export async function getPrivacyPolicy(apiPath, resObjName, setData) {
	try {
		const res = await axios.get(
			BASE_API_URL + apiPath + '?published=active'
		);

		setData(res.data[resObjName][0]);
	} catch (err) {
		console.log(err);
	}
}

export async function getFaq(apiPath, resObjName, setData, region) {
	try {
		const res = await axios.get(
			BASE_API_URL + apiPath + '?status=active' + `&region=${region}`
		);

		setData(res.data);
	} catch (err) {
		console.log(err);
	}
}

export async function getCorporates(apiPath, resObjName, setData, region) {
	try {
		const res = await axios.get(
			BASE_API_URL + apiPath + `?status=active&page_type=corporate&region=${region}`
		);

		setData(res.data[resObjName]);
	} catch (err) {
		console.log(err);
	}
}

export async function getInvestors(apiPath, resObjName, setData) {
	try {
		const res = await axios.get(
			BASE_API_URL + apiPath + '?status=active&page_type=investor'
		);

		setData(res.data[resObjName]);
	} catch (err) {
		console.log(err);
	}
}

export async function getDocuments(apiPath, resObjName, setData, selectedFilingType, selectedYear, currentPositions, search) {
	const baseUrl = BASE_API_URL + apiPath;
	const defaultParams = '?status=active&page_type=sec_filing';
	let url = `${baseUrl}${defaultParams}`;

	if (currentPositions) {
		url = `${url}&page=${currentPositions + 1}`
	}
  
	if (selectedFilingType) {
	  url = `${baseUrl}${defaultParams}&type=${selectedFilingType}`;
	}
  
	if (selectedYear && selectedYear !== "Year") {
	  url = `${url}&year=${selectedYear}`;
	}

	if (search) {
		url = `${url}&search=${search}`;
	}

	try {
	  const res = await axios.get(url);
	  setData(res.data);
	} catch (err) {
	  console.error(err);
	}
}

export async function getYearOptions(apiPath, resObjName, setData) {
	try {
		const res = await axios.get(
			BASE_API_URL + apiPath + '?status=active'
		);

		setData(res.data[resObjName]);
	} catch (err) {
		console.log(err);
	}
}

export async function getPosts(page, type, category) {
	try {
		const res = await axios.get(
			BASE_API_URL +
				'posts' +
				`?type=${type}&published=published&category=${category}${
					page ? `&page=${page}` : ''
				}`
		);

		return res.data;
	} catch (err) {
		console.log(err);
	}
}

export async function getInvestorPresentations(apiPath, resObjName, setData, tab, region) {
	try {
		const res = await axios.get(
			BASE_API_URL + apiPath + `?tab=${tab}&region=${region}`
		);

		setData(res.data[resObjName]);
	} catch (err) {
		console.log(err);
	}
}

export async function getStudies(parent) {
	try {
		const res = await axios.get(
			BASE_API_URL + 'studies' + `?parent=${parent}&published=published`
		);

		return res.data;
	} catch (err) {
		console.log(err);
	}
}

export async function getPapers(parent) {
	try {
		const res = await axios.get(
			BASE_API_URL + 'papers' + `?parent=${parent}&published=published`
		);

		return res.data;
	} catch (err) {
		console.log(err);
	}
}

export async function getMaterials(parent) {
	try {
		const res = await axios.get(
			BASE_API_URL + 'materials' + `?parent=${parent}&published=published`
		);

		return res.data;
	} catch (err) {
		console.log(err);
	}
}

export async function getMaterialVclas(parent) {
	try {
		const res = await axios.get(
			BASE_API_URL + 'material-vclases' + `?parent=${parent}&published=published`
		);

		return res.data;
	} catch (err) {
		console.log(err);
	}
}

export async function getPresentations(page, parent) {
	try {
		const res = await axios.get(
			BASE_API_URL +
				'presentations' +
				`?parent=${parent}&published=published${page ? `&page=${page}` : ''}`
		);

		return res.data;
	} catch (err) {
		console.log(err);
	}
}

export async function getPostsFromIndex(page) {
	try {
		const res = await axios.get(
			BASE_API_URL +
				'posts' +
				`?published=published${page ? `&&page=${page}` : ''}`
		);

		return res.data;
	} catch (err) {
		console.log(err);
	}
}

export async function getPost(id) {
	try {
		const res = await axios.get(BASE_API_URL + 'posts' + `/${id}`);

		return res.data;
	} catch (err) {
		console.log(err);
	}
}

export async function getPositions(page) {
	try {
		const res = await axios.get(
			BASE_API_URL +
				'positions' +
				`?published=published${page ? `&&page=${page}` : ''}`
		);

		return res.data;
	} catch (err) {
		console.log(err);
	}
}

export async function getPosition(id) {
	try {
		const res = await axios.get(BASE_API_URL + 'positions' + `/${id}`);

		return res.data;
	} catch (err) {
		console.log(err);
	}
}

export async function getCategories(section) {
	try {
		const res = await axios.get(
			BASE_API_URL + 'categories' + `?section=${section}`
		);

		return res.data;
	} catch (err) {
		console.log(err);
	}
}

export async function getManagers() {
	try {
		const res = await axios.get(BASE_API_URL + 'managers');

		return res.data;
	} catch (err) {
		console.log(err);
	}
}

export async function getBoardOfDirectors() {
	try {
		const res = await axios.get(BASE_API_URL + 'boards');

		return res.data;
	} catch (err) {
		console.log(err);
	}
}

export async function getPageId(pathname, resObjName, setData) {
	try {
		const res = await axios.get(BASE_API_URL + pathname);

		setData(res.data[resObjName]);
	} catch (err) {
		console.log(err);
	}
}

export const getProfile = (pathname, setUser) => {
	auth.currentUser?.getIdToken()
		.then((token) => {
			axios
				.create({ headers: { Authorization: `Bearer ${token}` } })
				.get(BASE_API_URL + pathname)
				.then((res) => {
					setUser(res.data.user);
				})
				.catch((err) => {
					console.log(err);
				});
		})
		.catch((err) => console.log(err));
};
