import React from 'react';
import ToggleButton from '../pages/ToggelButton';
import { getRandomSessionId, getSessionIdFromStorage, setDefaultSettingsForSession, storeSessionId } from '../sessionService';

const SettingsModal = ({ handleCloseModal, showSettingsModal, handleSaveSettings, handleCloseSettings }) => {
  const handleSave = () => {
    const sessionId = getSessionIdFromStorage();
    if (sessionId) {
      console.log('Settings saved for session ID:', sessionId);
    } else {
      const randomSessionId = getRandomSessionId();
      storeSessionId(randomSessionId);
      setDefaultSettingsForSession(randomSessionId);
      console.error('No session ID found in local storage');
    }
    handleCloseModal();
    handleSaveSettings();
  };

  return (
    <>
      {showSettingsModal && (
        <div className='modal-overlay2'>
          <div className='modal-content2'>
            <h2 className='modal-header'>Cookies Settings</h2>
            <ToggleButton />
            <div className='flex justify-around mt-4'>
              <button
                className='modal-button2 accept-button2'
                onClick={handleSave}
              >
                Save Settings
              </button>
              <button
                className='modal-button3 reject-button3'
                onClick={handleCloseSettings}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsModal;
