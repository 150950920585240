import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';

import { getPageId } from '../helpers/api-utils';

let EU_URL = process.env.REACT_APP_LIVE_EU_URL;
if (process.env.NODE_ENV === 'development') {
	EU_URL = process.env.REACT_APP_LOCAL_EU_URL;
}


const Security = () => {

  let pathname = 'security-pages/65642d632118adb0e5ed3836';
	if (window.location.href.includes(EU_URL)) {
		pathname = 'security-pages/65642d632118adb0e5ed3836';
	}

	const [data, setData] = useState(null);

	useEffect(() => {
		const resObjName = 'securityPage';
		getPageId(pathname, resObjName, setData);
	}, []);

	return (
		<>
			<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-4 lg:px-8'>
				<h1 className='text-primary-blue text-3xl pb-5'>
					{data && data.title}
				</h1>
				<div
					className='text-editor'
					dangerouslySetInnerHTML={{
						__html: data && data.body,
					}}
				></div>

        {/* <h2 className='pb-5 text-3xl text-primary-blue'>YOUR ROLE IN ADAGIO MEDICAL PRODUCT SECURITY</h2>
				<p className='py-2'>
          We value the contributions of the security research community. 
          If you believe you have identified a potential security vulnerability in one of our products or services,
          please follow the coordinated disclosure process below.
				</p>
        <h2 className='py-2 text-xl text-black'><strong>WHO TO CONTACT</strong></h2>
				<p className='py-2'>
          Email <a className='underline' href='mailto:product_security@adagiomedical.com'>product_security@adagiomedical.com</a>. We would prefer that your message be provided in English.
				</p>
				<h2 className='py-2 text-xl text-black'><strong>WHAT DETAILS TO PROVIDE</strong></h2>
        <ul className='list-outside px-4 list-disc'>
          <li>
            Your contact information, including name(s), organization name, email address and phone number so we can follow up with you. 
            We ask for contact information only to consult Adagio Medical records when addressing your submission. We never share your contact information.
          </li>
          <ul className='list-inside px-8 list-disc'>
            <li>When, where, and how it was discovered</li>
            <li>Which products/devices/systems it is impacting, including product numbers</li>
            <li>
              Whether you were able to access any protected health information or other personally-identifiable information about any user or the product or system in
              which you disclosed the vulnerability. Please do NOT include any protected health information or other personally-identifiable information about others in
              your email submission.
            </li>
            <li>Any additional information you think will be helpful to us, including details on the testing environment and tools used to conduct the testing</li>
          </ul>
          <li>
            Whether you have notified anyone else about the potential vulnerability, such as regulatory agencies, vendors, vulnerability coordinators, etc.
          </li>
        </ul>

        <h2 className='py-2 text-xl text-black'><strong>WHAT ADAGIO MEDICAL WILL DO</strong></h2>
        <ul className='list-outside px-4 list-disc'>
          <li>
            Within five business days, Adagio Medical will confirm we have received your submission and give you the name of a contact person.
          </li>
          <li>
            We will notify the appropriate security engineers who may want to follow up with you to better understand what you’ve found, or to confirm technical details.
          </li>
          <li>We will investigate the potential vulnerability.</li>
          <li>We will conduct a risk analysis to determine appropriate action.</li>
          <li>Once determined, we will provide you with a summary of our findings.</li>
          <li>We may publicly acknowledge your contribution to improve the security of our products and services, subject to your agreement.</li>
        </ul>

        <h2 className='py-2 text-xl text-black'><strong>IMPORTANT INFORMATION</strong></h2>
        <ul className='list-outside px-4 list-disc'>
          <li>We ask that you comply with all laws and regulations when conducting your research, and avoid actions that could harm products or people, such as brute force testing,
            tests on active devices, tests on software in production settings, actions taken to exploit any vulnerability, and actions that result in a change to a product or system after
            the test is conducted.
          </li>
          <li>If you have identified a security vulnerability in an Adagio Medical product and would prefer to disclose the matter directly to the regulatory agency rather than Adagio
            Medical, please contact the appropriate regulatory agency.
          </li>
          <li>By submitting information, you agree that your submission will be governed by Adagio Medical’s <a className='underline text-sky-700' href='https://us.adagiomedical.com/privacy-policy'>Privacy Policy</a> and <a className='underline text-sky-700' href='https://us.adagiomedical.com/terms-of-use'>Terms of Use.</a></li>
          <li>We reserve the right to change any aspect of our coordinated disclosure process at any time without notice, and to make exceptions to it on a case-by-case basis.</li>
        </ul> */}
			</main>
		</>
	);
};

export default Security;
