import Header from './header';
import Footer from './footer';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
config.autoAddCss = false;

function Layout() {
	const [showModal, setShowModal] = useState(false);
	const [showSettingsModal, setShowSettingsModal] = useState(false);
	const [showPopup, setShowPopup] = useState(false);

	useEffect(() => {
		const session = localStorage.getItem("selectedSessionId");
		if(!session){
			setShowModal(true); // Show modal on every route change
		}
	}, []);

	const handleSettingsClick = () => {
		setShowSettingsModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleCloseSettings = () => {
		setShowSettingsModal(false);
	};

	const handleOpenModal = ( data ) => {
		setShowModal(data);
	}

	const handleSaveSettings = () => {
		// Implement save settings logic here
		setShowSettingsModal(false);
	};

	const handleClosePopup = () => {
		setShowPopup(false);
	};

	const handleOpenPopup = () => {
		setShowPopup(true);
	}

	return (
		<>
			<Header isEu={false} />
			<div className='bg-gray-100'>
				<Outlet />
			</div>
			<Footer
				handleOpenModal={handleOpenModal}
				showModal={showModal} 
				showSettingsModal={showSettingsModal}
				handleCloseModal={handleCloseModal}
				handleSettingsClick={handleSettingsClick}
				handleSaveSettings={handleSaveSettings}
				handleCloseSettings={handleCloseSettings}
				showPopup={showPopup}
				handleClosePopup={handleClosePopup}
				handleOpenPopup={handleOpenPopup}
			/>
		</>
	);
}

export default Layout;
