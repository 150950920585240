import React from 'react';
import { Field } from 'react-final-form';
import {
	FieldSet,
	Subtitle,
	TitleSet,
	Card,
	Input,
	FORM_GRID,
} from '../inputs';
import RouterLink from '../../../components/common';

export default function Fields() {
	return (
		<FieldSet>
			<TitleSet>
				<Subtitle>
					Don't have your account?{' '}
					<RouterLink path='/register'>Sign up</RouterLink>
					<br />
					<RouterLink path='/reset-password'>Forgot your password?</RouterLink>
				</Subtitle>
			</TitleSet>
			<Card>
				<Field name='email'>
					{({ input, meta }) => (
						<Input
							input={input}
							meta={meta}
							label='Email'
							type='email'
							size={FORM_GRID[3]}
							placeholder=''
							autoComplete='on'
							required={true}
							disabled={false}
						></Input>
					)}
				</Field>
				<Field name='password'>
					{({ input, meta }) => (
						<Input
							input={input}
							meta={meta}
							label='Password'
							type='password'
							size={FORM_GRID[3]}
							placeholder=''
							autoComplete='on'
							required={true}
							disabled={false}
						></Input>
					)}
				</Field>
			</Card>
		</FieldSet>
	);
}
