// dependencies
import React, { useState } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
// redux
import { useDispatch } from "react-redux";
import { setUrl } from "../../store/slices/fileSlice";
// components
import { storage } from "../../firebase";

export default function FileInput({ title, folder, id, required = false }) {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);

  const handleFile = (e) => {
    const file = e.target.files[0];
    uploadFiles(file);
  };

  const uploadFiles = (file) => {
    if (!file) return;

    const storageRef = ref(storage, `/${folder}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        setProgress(prog);
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          dispatch(setUrl({ id, value: url }));
        });
      }
    );
  };

  return (
    <div className="col-span-6 sm:col-span-6">
      <label className="block text-sm font-medium text-gray-700">{title}</label>
      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="text-sm text-gray-600 w-full text-center">
            <input
              name={id}
              onChange={handleFile}
              type="file"
              className="rounded border border-solid border-gray-300 mb-1"
              required={required}
            />
          </div>
          <p className="text-xs text-gray-500">
            PNG, JPG, GIF, PDF up to 100MB
          </p>
          {progress > 0 && <p>{progress} "% done"</p>}
        </div>
      </div>
    </div>
  );
}
