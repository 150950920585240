import React, { useState, useEffect } from 'react';
import {
	ArrowNarrowLeftIcon,
	ArrowNarrowRightIcon,
} from '@heroicons/react/solid';
import { PlayIcon } from '@heroicons/react/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';
import { getPresentations } from '../../helpers/api-utils';

const currentClass =
	'border-secondary-blue text-primary-blue border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium';
const normalClass =
	'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium';

const PresentationList = ({ parent }) => {
	const [currentPresentations, setCurrentPresentations] = useState({
		presentations: null,
		current: 0,
		pages: null,
		numberOfPages: 0,
	});

	const onPageChange = async (index) => {
		const response = await getPresentations(index + 1, parent._id);

		setCurrentPresentations((prevState) => ({
			...prevState,
			presentations: response.presentations,
			current: index,
		}));
	};

	useEffect(() => {
		async function fetchData() {
			const response = await getPresentations(1, parent._id);
			const numberOfPages = Math.ceil(response.count / 5);
			const pages = new Array(numberOfPages).fill('*');

			setCurrentPresentations((prevState) => ({
				...prevState,
				presentations: response.presentations,
				pages: pages,
				numberOfPages: numberOfPages,
			}));
		}

		fetchData();
	}, []);

	return (
		<>
			{currentPresentations.presentations &&
				currentPresentations.presentations.length > 0 && (
					<>
						<h2 className='mt-3 flex flex-col items-center md:flex-row md:justify-center font-semibold'>
							<a
								href='#'
								onClick={(e) => {
									e.preventDefault();
								}}
								className={'text-primary-blue block'}
							>
								{parent.technology}
							</a>
							<span className='md:block mx-3 hidden'>{'|'}</span>
							<a
								href='#'
								onClick={(e) => {
									e.preventDefault();
								}}
								className={'text-primary-blue block mt-1 md:mt-0'}
							>
								{parent.condition}
							</a>
						</h2>
						<div className='mt-3'>
							<div className='relative'>
								<div className='absolute inset-0 flex items-center'>
									<div className='w-full border-t border-gray-300' />
								</div>
								<div className='relative flex justify-center text-sm'>
									<span className='px-2 bg-gray-100 text-gray-400 text-lg'>
										<FontAwesomeIcon icon={faSnowflake} />
									</span>
								</div>
							</div>
						</div>
						<div className='min-w-full bg-white py-8 px-4 shadow sm:px-10 mt-4'>
							{currentPresentations.presentations &&
								currentPresentations.presentations.map((presentation) => (
									<div
										key={presentation._id}
										className='group relative p-4 sm:p-6'
									>
										<div className='mt-3 sm:flex'>
											<div className='rounded-lg'>
												<img
													src={presentation.imageUrl}
													alt={presentation.alt}
													className='w-full h-full object-center object-cover sm:w-full sm:h-full'
												/>
											</div>

											<div className='mt-6 sm:mt-0 sm:ml-6'>
												<p className='mt-2 text-sm font-medium text-gray-900'>
													{presentation.title}
												</p>
												<p className='mt-3 text-sm text-gray-500'>
													{presentation.subtitle}
												</p>
												<p className='mt-10 text-sm text-gray-500'>
													<a href={presentation.url} className='flex'>
														<PlayIcon className='w-5 h-5 mr-2' />{' '}
														{'Watch video'}
													</a>
												</p>
											</div>
										</div>
									</div>
								))}
						</div>
						<nav className='border-t border-gray-200 px-4 flex items-center justify-between sm:px-0 mb-8'>
							<div className='-mt-px w-0 flex-1 flex'>
								<a
									href='#'
									onClick={(e) => {
										e.preventDefault();
										if (currentPresentations.current > 0) {
											onPageChange(currentPresentations.current - 1);
										}
									}}
									className='border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'
								>
									<ArrowNarrowLeftIcon
										className='mr-3 h-5 w-5 text-gray-400'
										aria-hidden='true'
									/>
									Previous
								</a>
							</div>
							<div className='hidden md:-mt-px md:flex'>
								{currentPresentations.pages &&
									currentPresentations.pages.map((el, index) => (
										<a
											key={index}
											href='#'
											onClick={(e) => {
												e.preventDefault();
												onPageChange(index);
											}}
											className={
												index === currentPresentations.current
													? currentClass
													: normalClass
											}
										>
											{index + 1}
										</a>
									))}
							</div>
							<div className='-mt-px w-0 flex-1 flex justify-end'>
								<a
									href='#'
									onClick={(e) => {
										e.preventDefault();
										if (
											currentPresentations.current <
											currentPresentations.numberOfPages - 1
										) {
											onPageChange(currentPresentations.current + 1);
										}
									}}
									className='border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300'
								>
									Next
									<ArrowNarrowRightIcon
										className='ml-3 h-5 w-5 text-gray-400'
										aria-hidden='true'
									/>
								</a>
							</div>
						</nav>
					</>
				)}
		</>
	);
};

export default PresentationList;
