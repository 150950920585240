import React, { useState, useEffect } from 'react';
import Hero from '../components/hero';
import SecondStudiesSection from '../components/second-studies-section';
import Spinner from '../components/spinner';
import { getPage } from '../helpers/api-utils';

export default function ClinicalEvidenceStudies() {
	const [data, setData] = useState(null);

	useEffect(() => {
		const apiPath = 'clinical-evidence-studies-pages';
		const resObjName = 'studiesPages';
		getPage(apiPath, resObjName, setData);
	}, []);
	return (
		<>
			{data ? (
				<>
					<Hero data={data.hero} />
					<SecondStudiesSection />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
}
