import React, { useState, useEffect } from 'react';
import Hero from '../components/hero';
import SecondPressReleasesSection from '../components/second-press-releases-section';
import { getPage } from '../helpers/api-utils';
import Spinner from '../components/spinner';

export default function PressReleases() {
	const [data, setData] = useState(null);

	useEffect(() => {
		const apiPath = 'press-releases-pages';
		const resObjName = 'pressReleasesPages';
		getPage(apiPath, resObjName, setData);
	}, []);
	return (
		<>
			{data ? (
				<>
					<Hero data={data.hero} />
					<SecondPressReleasesSection data={data.second} />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
}
