import React, { useState, useEffect } from 'react';
import {
	LinkIcon,
} from '@heroicons/react/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';
import { getStudies } from '../../helpers/api-utils';

const statusColorClass = {
	green: 'text-green-500',
	red: 'text-red-500',
	yellow: 'text-yellow-500',
	blue: 'text-blue-500',
};

const StudyList = ({ parent }) => {
	const [currentStudies, setCurrentStudies] = useState({
		studies: null,
		current: 0,
		pages: null,
		numberOfPages: 0,
	});

	const changeStatusColor = (status) => {
		if (status.toUpperCase() === 'COMPLETED') {
			return statusColorClass.red;
		}

		if (status.toUpperCase() === 'RECRUITING') {
			return statusColorClass.green;
		}

		if (status.toUpperCase() === 'ACTIVE, NOT RECRUITING') {
			return statusColorClass.yellow;
		}

		if (status.toUpperCase() === 'NOT YET RECRUITING') {
			return statusColorClass.blue;
		}
	};

	useEffect(() => {
		async function fetchData() {
			const response = await getStudies(parent._id);

			setCurrentStudies((prevState) => ({
				...prevState,
				studies: response.studies,
			}));
		}

		fetchData();
	}, []);

	return (
		<>
			{currentStudies.studies && currentStudies.studies.length > 0 && (
				<>
					<h2 className='mt-8 flex flex-col items-center md:flex-row md:justify-center font-semibold'>
						<a
							href='#'
							onClick={(e) => {
								e.preventDefault();
							}}
							className={'text-primary-blue block'}
						>
							{parent.technology}
						</a>
						<span className='md:block mx-3 hidden'>{'|'}</span>
						<a
							href='#'
							onClick={(e) => {
								e.preventDefault();
							}}
							className={'text-primary-blue block mt-1 md:mt-0'}
						>
							{parent.condition}
						</a>
					</h2>
					<div className='mt-3'>
						<div className='relative'>
							<div className='absolute inset-0 flex items-center'>
								<div className='w-full border-t border-gray-300' />
							</div>
							<div className='relative flex justify-center text-sm'>
								<span className='px-2 bg-gray-100 text-gray-400 text-lg'>
									<FontAwesomeIcon icon={faSnowflake} />
								</span>
							</div>
						</div>
					</div>
					<div className='-mx-px border-l border-gray-200 grid grid-cols-2 sm:mx-0 md:grid-cols-3 lg:grid-cols-4 mt-3 border-t'>
						{currentStudies.studies &&
							currentStudies.studies.map((study) => (
								<div
									key={study._id}
									className='group relative p-4 border-r border-gray-200 sm:p-6 border-b'
								>
									<a
										href={study.url}
										className='block rounded-lg overflow-hidden bg-white group-hover:opacity-75'
										alt={study.alt}
										target='_blank'
									>
										<div className='w-full text-center py-2 bg-primary-blue h-auto text-white'>
											{study.title}
										</div>
										<div className='w-full text-center py-4 px-4 h-auto text-primary-blue text-sm'>
											{study.subtitle}
										</div>
										<div className='w-full flex justify-center py-4 px-4 h-auto text-primary-blue text-sm'>
											<LinkIcon className='h-5 w-5 text-gray-800' />{' '}
											<span className='ml-2'>{study.filename}</span>
										</div>
										{/* <img
									src={study.imageSrc}
									alt={study.imageAlt}
									className='w-full h-full object-center object-cover'
								/> */}
									</a>
									<div className='pt-10 pb-4 uppercase'>
										<p className='text-sm font-semibold text-gray-900'>
											Status:{' '}
											<span className={changeStatusColor(study.status)}>
												{study.status}
											</span>
										</p>
										<p className='text-sm font-semibold text-gray-900'>
											Condition(s): {study.conditions}
										</p>
										<p className='text-sm font-semibold text-gray-900'>
											Number of Patients: {study.numberOfPatients}
										</p>
										<p className='text-sm font-semibold text-gray-900'>
											Number of centers: {study.numberOfCenters}
										</p>
										<p className='text-sm font-semibold text-gray-900'>
											COUNTRIES: {study.countries}
										</p>
									</div>
								</div>
							))}
					</div>
				</>
			)}
		</>
	);
};

export default StudyList;
