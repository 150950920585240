import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';

import { getPageId } from '../helpers/api-utils';

let EU_URL = process.env.REACT_APP_LIVE_EU_URL;
if (process.env.NODE_ENV === 'development') {
	EU_URL = process.env.REACT_APP_LOCAL_EU_URL;
}

export default function Privacy() {
	let pathname = 'privacy-pages/62e9583ddb58a8757014af03';
	if (window.location.href.includes(EU_URL)) {
		pathname = 'privacy-pages/62e9589fdb58a8757014af05';
	}

	const [data, setData] = useState(null);

	useEffect(() => {
		const resObjName = 'privacyPage';
		getPageId(pathname, resObjName, setData);
	}, []);

	return (
		<>
			<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
				<h1 className='text-primary-blue text-3xl pb-5'>
					{data && data.title}
				</h1>
				<div
					className='ql-editor'
					dangerouslySetInnerHTML={{
						__html: data && data.body,
					}}
				></div>
			</main>
		</>
	);
}
