import React, { useState } from 'react';
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import "../pages/style/ToggelButton.css";
import { getSessionIdFromStorage, getSettingsForSession, updateSettingForSession } from '../sessionService';

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>')`, // Corrected path to cross mark
      right: 12
    }
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2
  }
}));

const CustomizedSwitches = () => {
  const sessionId = getSessionIdFromStorage();
  const [toggleStates, setToggleStates] = useState(() => getSettingsForSession(sessionId));

  const handleToggleChange = (event) => {
    const { name, checked } = event.target;
    setToggleStates(prevState => ({
      ...prevState,
      [name]: checked,
    }));
    updateSettingForSession(sessionId, name, checked);
  };

  return (
    <>
      <p>
        We use cookies and similar technologies to operate the website/app without error and for the purposes described in the controllers below. 
        If you click on "Save settings" and do not make any further settings, then you reject all cookies that require consent. 
        You also have the option of selecting cookies individually via the slider buttons below and clicking on "Save settings" or additionally on the website via the "Cookie settings". 
        You can change or revoke your cookie settings at any time.
      </p>
      <FormGroup>
        <div className="check-cookies-btn">
          <FormControlLabel
            control={<Android12Switch checked={toggleStates.technicallyNecessary} onChange={handleToggleChange} name="technicallyNecessary" />}
            label="Technically necessary"
          />
          <FormControlLabel
            control={<Android12Switch checked={toggleStates.socialEmbeds} onChange={handleToggleChange} name="socialEmbeds" />}
            label="Social Embeds"
          />
          <FormControlLabel
            control={<Android12Switch checked={toggleStates.display} onChange={handleToggleChange} name="display" />}
            label="Display"
          />
          <FormControlLabel
            control={<Android12Switch checked={toggleStates.mobileApp} onChange={handleToggleChange} name="mobileApp" />}
            label="Mobile app"
          />
        </div>
        <div className="check-cookies-btn">
          <FormControlLabel
            control={<Android12Switch checked={toggleStates.statisticsRangeMeasurement} onChange={handleToggleChange} name="statisticsRangeMeasurement" />}
            label="Statistics / range measurement"
          />
          <FormControlLabel
            control={<Android12Switch checked={toggleStates.analysis} onChange={handleToggleChange} name="analysis" />}
            label="Analysis"
          />
          <FormControlLabel
            control={<Android12Switch checked={toggleStates.advertising} onChange={handleToggleChange} name="advertising" />}
            label="Advertising"
          />
          <FormControlLabel
            control={<Android12Switch checked={toggleStates.personalization} onChange={handleToggleChange} name="personalization" />}
            label="Personalization"
          />
        </div>
      </FormGroup>
    </>
  );
};

export default CustomizedSwitches;
