import { v4 as uuidv4 } from 'uuid';
import { FOOTER_COMPONENT, FOOTER_URLS } from './data';
import Modal from '../../pages/Modal';
import SettingsModal from '../../pages/SettingsModal';
import { useEffect, useState } from 'react';
import LegalNoticePopup from '../../pages/LegalNoticePopup';

export default function Footer({ 
	handleOpenModal, showModal, showSettingsModal, handleCloseModal,
	handleSettingsClick, handleSaveSettings, handleCloseSettings,
	showPopup, handleClosePopup, handleOpenPopup
}) {
	const currentUrl = window.location.host;
	const { navigation, items, info } = FOOTER_COMPONENT;
	const [region, setRegion] = useState("");

	const handleShowModal = () => {
		handleOpenModal(true);
	}

	const handleShowLegalNotice = () => {
		handleOpenPopup(true);
	}

	useEffect(() => {
    if (currentUrl.includes("localhost:3300") 
			|| currentUrl.includes("aidandang.firebase") 
			|| currentUrl.includes("us.adagiomedical.com")
			|| currentUrl.includes("adagio-us-dev.web.app")
		) {
			setRegion("US");
		} else {
			setRegion("EU");
		}
	}, [currentUrl]);

	const filteredItems = items.filter(item => item.region === "BOTH" || item.region === region);

	// const handleShowModal = () => {
	// 	handleOpenModal(true);
	// }

	return (
		<footer className='bg-white'>
			<div className='max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8'>
				<div className='flex justify-center space-x-6 md:order-2'>
					{navigation.map((item) => (
						<a
							key={uuidv4()}
							href={item.href}
							target='_blank'
							rel='noreferrer'
							className='text-gray-400 hover:text-gray-500'
						>
							<span className='sr-only'>{item.name}</span>
							<item.icon className='h-6 w-6' aria-hidden='true' />
						</a>
					))}
				</div>
				<div className='mt-8 md:mt-0 md:order-1 flex flex-col items-center md:flex md:flex-row md:justify-between md:items-center'>
					<div className='mb-6 md:mb-0'>
						<img
							className='block h-12 w-auto'
							src={FOOTER_URLS.logo}
							alt='Workflow'
						/>
					</div>
					<p className='text-center md:text-left text-gray-400 text-xs leading-4 mx-6'>
						{info.address}
						<br />
						{info.phone}
						<br />
						{info.copyright}
					</p>
				</div>
			</div>
			<div className='relative bg-primary-blue'>
				<div className='max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8'>
					<div className='text-center'>
					{filteredItems.map((item, index) => (
							<span key={uuidv4()}>
								{item.name === "COOKIE SETTINGS" ? (
									<button 
										className='text-xs font-medium text-white'
										onClick={handleShowModal}
									>
										{item.name}
									</button>
								) : item.name === "LEGAL NOTICE" ? (
									<button 
										className='text-xs font-medium text-white'
										onClick={handleShowLegalNotice}
									>
										{item.name}
									</button>
								) : (
									<a 
										href={item.href} 
										className='text-xs font-medium text-white'
									>
										{item.name}
									</a>
								)}
								{index !== filteredItems.length - 1 && <span className='mr-4'></span>}
							</span>
						))}
					</div>
				</div>
			</div>

			{/* Main Modal */}
			<Modal
				showModal={showModal}
				handleCloseModal={handleCloseModal}
				handleSettingsClick={handleSettingsClick}
			/>

			{/* Settings Modal */}
			<SettingsModal
				handleCloseModal={handleCloseModal}
				showSettingsModal={showSettingsModal}
				handleSaveSettings={handleSaveSettings}
				handleCloseSettings={handleCloseSettings}
			/>
			{/* Legal Notice Modal */}
			<LegalNoticePopup
				showPopup={showPopup}
				handleClosePopup={handleClosePopup}
			/>
		</footer>
	);
}