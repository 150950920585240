export default function BottomProductsSection({ data }) {
	return (
		<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 pt-5'>
			<div className='min-w-full w-full bg-white py-8 px-4 sm:px-10 bg-gray-200'>
				<div
					dangerouslySetInnerHTML={{
						__html: data.detailsList,
					}}
				></div>
			</div>
		</section>
	);
}
