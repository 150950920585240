export const formatDateString = (inputDate) => {
    const dateObject = new Date(inputDate);
  
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    const monthName = monthNames[dateObject.getMonth()];
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();
  
    return `${monthName} ${day}, ${year}`;
}
