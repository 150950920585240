/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import SecFiling from '../sec-filing';
import InvestorComponent from '../investor';
import GovernanceComponent from '../governance';
import { useLocation } from 'react-router-dom';


const InvestorSection = ({ data, onHeroDataChange, selectedHeroIndex }) => {
	const location = useLocation();
	const [currentUrl, setCurrentUrl] = useState(location.pathname.split('/')[1]);

	useEffect(() => {
		setCurrentUrl(location.pathname.split('/')[1])
	}, [location])

	return (
		<section className="max-w-7xl mx-auto sm:px-6 lg:px-8 mt-5">
			<div
				style={{ backgroundImage: `url('${data.bgImgUrl}')` }}
				className="w-full flex justify-center py-10 px-4 sm:px-6 md:px-8 lg:px-10"
			>
				<div className="w-full max-w-screen-lg bg-white py-8 px-4 shadow sm:px-6 md:px-8 lg:px-10 flex items-center">
					<div className="w-full max-w-3xl mt-3 mb-12 px-8">
						{currentUrl === 'corporate' && <GovernanceComponent />}
						{currentUrl === 'sec-filing' && <SecFiling />}
						{currentUrl === 'events-presentations' && <InvestorComponent />}
					</div>
					<nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0"></nav>
				</div>
			</div>
		</section>
	);	
};

export default InvestorSection;
