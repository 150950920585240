import React, { useState, useEffect } from 'react';
import Hero from '../components/hero';
import SecondPresentationsSection from '../components/second-presentations-section';
import { getPage } from '../helpers/api-utils';
import Spinner from '../components/spinner';

export default function ClinicalEvidencePresentations() {
	const [data, setData] = useState(null);

	useEffect(() => {
		const apiPath = 'clinical-evidence-presentations-pages';
		const resObjName = 'presentationsPages';
		getPage(apiPath, resObjName, setData);
	}, []);
	return (
		<>
			{data ? (
				<>
					<Hero data={data.hero} />
					<SecondPresentationsSection data={data.second} />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
}
