import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';
import { getPosition } from '../../helpers/api-utils';

const SecondPostitionSection = ({ data, id }) => {
	const [position, setPosition] = useState(null);

	useEffect(() => {
		async function fetchData() {
			const response = await getPosition(id);

			setPosition(response.position);
		}

		fetchData();
	}, []);
	return (
		<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-5'>
			<div
				style={{ backgroundImage: `url(\'${data.bgImgUrl}\')` }}
				className='w-full flex justify-center py-10 px-12 sm:px-20 md:px-28 lg:px-36'
			>
				{position && (
					<div className='min-w-full bg-white py-8 px-4 shadow sm:px-10'>
						<h2 className='text-center font-semibold text-primary-blue'>
							{position.title}
						</h2>
						<div className='mt-3'>
							<div className='relative'>
								<div className='absolute inset-0 flex items-center'>
									<div className='w-full border-t border-gray-300' />
								</div>
								<div className='relative flex justify-center text-sm'>
									<span className='px-2 bg-white text-gray-400 text-lg'>
										<FontAwesomeIcon icon={faSnowflake} />
									</span>
								</div>
							</div>
						</div>
						<div className='mt-3'>
							<div
								dangerouslySetInnerHTML={{
									__html: position.body,
								}}
							></div>
						</div>
						<div className='mt-5'>
							<a
								href={'/apply'}
								className='flex items-center justify-center px-4 py-3 bg-primary-blue border border-white text-sm rounded-md shadow-sm text-white hover:bg-primary-blue hover:bg-opacity-60 sm:px-8'
							>
								APPLY
							</a>
						</div>
					</div>
				)}
			</div>
		</section>
	);
};

export default SecondPostitionSection;
