import React, { useState, useEffect } from 'react';
import { fetchSessions, getRandomSessionId, storeSessionId, setDefaultSettingsForSession } from '../sessionService';

const Modal = ({ showModal, handleCloseModal, handleSettingsClick }) => {
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    const getSessions = async () => {
      const fetchedSessions = await fetchSessions();
      setSessions(fetchedSessions);
    };

    getSessions();
  }, []);

  const handlePreferencesClick = () => {
    if (sessions?.length > 0) {
      const randomSessionId = getRandomSessionId(sessions);
      storeSessionId(randomSessionId);
      setDefaultSettingsForSession(randomSessionId);
    }
    handleSettingsClick();
  };

  const handleAcceptClick = () => {
    if (sessions?.length === 0) {
      const randomSessionId = getRandomSessionId(sessions);
      storeSessionId(randomSessionId);
      setDefaultSettingsForSession(randomSessionId);
    }
    handleCloseModal();
  };

  return (
    <>
      {showModal && (
        <div className="fixed inset-x-0 left-4 bottom-12 bg-opacity-50 z-50 shadow-2xl">
          <div className="bg-white p-5 w-11/12 max-w-2xl rounded-t-lg border border-gray-300">
            <p className="mb-4">
              We and our business partners may use cookies, pixels, and similar technologies (collectively, "cookies") 
              to collect information about you, including about your browsing activities and devices. 
              By using this website, you agree to this collection and disclosure of your information in accordance with our Privacy Policy, 
              Terms of Use, and your cookie settings. 
              By clicking "Accept" you consent to our use of all cookies and agree that we may use non-EU/EEA cookie providers to do so.
            </p>
            <div className="flex flex-wrap">
              <button
                className="modal-button border border-black bg-white !text-black px-4 py-2 rounded"
                onClick={handlePreferencesClick}
              >
                Preferences
              </button>

              <button
                className="modal-button bg-blue-900 text-white px-4 py-2 rounded"
                onClick={handleCloseModal}
              >
                Decline
              </button>
              <button
                className="modal-button bg-blue-900 text-white px-4 py-2 rounded"
                onClick={handleAcceptClick}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
