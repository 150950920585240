import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';

export default function FourCellSection({ data }) {
	return (
		<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-5'>
			<div className='grid grid-cols-1 gap-0.5 md:grid-cols-2 bg-white'>
				{data.map((cell) => (
					<div key={uuidv4()} className='col-span-1'>
						<Link
							to={cell.readmorePath}
							className='relative w-full h-60 p-6 flex flex-col overflow-hidden hover:opacity-75 xl:w-auto'
						>
							<span aria-hidden='true' className='absolute inset-0'>
								<img
									src={cell.imageUrl}
									alt={cell.alt}
									className='w-full h-full object-center object-cover'
								/>
							</span>
							<span
								aria-hidden='true'
								className='absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50'
							/>
							<span className='relative top-10 left-10 top-10 text-xl font-bold text-white'>
								{cell.title} <br />
								<span className='text-sm'>see more {'>'}</span>
							</span>
						</Link>
					</div>
				))}
			</div>
		</section>
	);
}
