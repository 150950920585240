export const FOOTER_URLS = {
	logo: 'https://firebasestorage.googleapis.com/v0/b/adagio-cms.appspot.com/o/common%2Fadagio-logo.svg?alt=media&token=3c4f96a0-09c3-4600-9640-960bb1de1f95',
};

export const FOOTER_COMPONENT = {
	navigation: [
		{
			name: 'LinkedIn',
			href: 'https://www.linkedin.com/company/adagio-medical-inc-/mycompany/',
			icon: (props) => (
				<svg fill='currentColor' viewBox='0 0 24 24' {...props}>
					<path d='M20.25 0H3.75C1.683 0 0 1.683 0 3.75v16.5C0 22.317 1.683 24 3.75 24h16.5c2.067 0 3.75-1.683 3.75-3.75V3.75C24 1.683 22.317 0 20.25 0zM7.5 20.25h-3V9h3v11.25zm-1.5-13.5a1.875 1.875 0 1 1 .001-3.751A1.875 1.875 0 0 1 6 6.75zm13.5 13.5h-3v-6c0-1.575-.9-2.25-2.25-2.25s-2.25.675-2.25 2.25v6h-3V9h3v1.5C9 8.175 10.725 6 14.25 6c2.625 0 4.5 1.5 4.5 4.5v7.5z'/>
				</svg>
			),
		},
		{
			name: 'Twitter',
			href: 'https://twitter.com/AdagioMedical',
			icon: (props) => (
				<svg fill='currentColor' viewBox='0 0 24 24' {...props}>
					<path d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' />
				</svg>
			),
		},
	],
	items: [
		{
			name: 'CONTACT US',
			href: '/contact',
			region: "BOTH",
		},
		{
			name: 'PRIVACY POLICY',
			href: '/privacy-policy',
			region: "BOTH",
		},
		{
			name: 'TERMS OF USE',
			href: '/terms-of-use',
			region: "BOTH",
		},
		{
			name: "PRODUCT SECURITY",
			href: '/product-security',
			region: "BOTH",
		},
		{
			name: "COOKIE SETTINGS",
			href: '/',
			region: "BOTH",
		},
		{
			name: "LEGAL NOTICE",
			href: "/",
			region: "EU"
		},
		{
			name: "COOKIE SETTINGS",
			href: '/'
		}
	],
	info: {
		address: '26051 Merit Cir #102, Laguna Hills, CA 92653.',
		phone: 'Contact Number: (949) 348-1188',
		copyright: `All rights reserved. © ${new Date().getFullYear()} Adagio Medical, Inc.`,
	},
};
