import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Hero from '../components/hero';
import SecondPositionSection from '../components/second-position-section';
import { getPage } from '../helpers/api-utils';

const CareersDetails = () => {
	const params = useParams();
	const [data, setData] = useState(null);

	useEffect(() => {
		const apiPath = 'careers-pages';
		const resObjName = 'careersPages';
		getPage(apiPath, resObjName, setData);
	}, []);

	return (
		<>
			{data && (
				<>
					<Hero data={data.hero} />
					<SecondPositionSection data={data.second} id={params.id} />
				</>
			)}
		</>
	);
};

export default CareersDetails;
