import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';

export default function BottomSection({ data }) {
	return (
		<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-5'>
			<div
				style={{ backgroundImage: `url(\'${data.bgImgUrl}\')` }}
				className='w-full flex justify-center py-10 px-12 sm:px-20 md:px-28 lg:px-36 bg-cover bg-center'
			>
				<div className='bg-white shadow min-w-full'>
					<main className='relative lg:min-h-full'>
						<div className='h-80 overflow-hidden lg:absolute lg:w-1/2 lg:h-full lg:pr-4 xl:pr-10'>
							<img
								src={data.imageUrl}
								alt='TODO'
								className='h-full w-full object-center object-cover'
							/>
						</div>

						<div className='py-8 px-4 sm:px-10'>
							<div className='mx-auto lg:grid lg:grid-cols-2'>
								<div className='lg:col-start-2'>
									<h2 className='text-center font-semibold text-primary-blue'>
										{data.title}
									</h2>
									<div className='mt-3'>
										<div className='relative'>
											<div className='absolute inset-0 flex items-center'>
												<div className='w-full border-t border-gray-300' />
											</div>
											<div className='relative flex justify-center text-sm'>
												<span className='px-2 bg-white text-gray-400 text-lg'>
													<FontAwesomeIcon icon={faSnowflake} />
												</span>
											</div>
										</div>
									</div>
									<div className='mt-3'>{data.body}</div>
									<div className='mt-3'>
										<a
											href={data.link}
											className='font-medium text-primary-blue hover:text-secondary-blue'
										>
											{data.linkLabel} ...
										</a>
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
		</section>
	);
}
