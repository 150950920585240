import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const fileSlice = createSlice({
	name: 'file',
	initialState,
	reducers: {
		setUrl: (state, action) => {
			state[action.payload.id] = action.payload.value;
		},
	},
});

export const { setUrl } = fileSlice.actions;

export default fileSlice.reducer;
