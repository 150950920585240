import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';

const OurMissionSection = ({ data }) => {
	return (
		<>
			<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-5' id='ourMission'>
				<div className='w-full flex justify-center py-10 px-12 sm:px-20 md:px-28 lg:px-36'>
					<div className='min-w-full py-8 px-4 sm:px-10'>
						<h2 className='text-center font-semibold text-primary-blue'>
							{data?.title}
						</h2>
						<div className='mt-3'>
							<div className='relative'>
								<div className='absolute inset-0 flex items-center'>
									<div className='w-full border-t border-gray-300' />
								</div>
								<div className='relative flex justify-center text-sm'>
									<span className='px-2 bg-gray-100 text-gray-400 text-lg'>
										<FontAwesomeIcon icon={faSnowflake} />
									</span>
								</div>
							</div>
						</div>
						<div className='mt-3'>
							<div
								dangerouslySetInnerHTML={{
									__html: data?.teaser,
								}}
								className='text-center'
							></div>
						</div>
					</div>
				</div>
				<div className='grid grid-cols-8 gap-0.5 bg-white'>
					<div className='col-span-2'>
						<img
							src={data?.image01Url.imageUrl}
							alt={data?.image01Url.alt}
							className='w-full h-full object-center object-cover'
						/>
					</div>
					<div className='col-span-4'>
						<img
							src={data?.image02Url.imageUrl}
							alt={data?.image02Url.alt}
							className='w-full h-full object-center object-cover'
						/>
					</div>
					<div className='col-span-2'>
						<img
							src={data?.image03Url.imageUrl}
							alt={data?.image03Url.alt}
							className='w-full h-full object-center object-cover'
						/>
					</div>
				</div>
			</section>
		</>
	);
};

export default OurMissionSection;
