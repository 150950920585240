import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';

const SecondSection = ({ data }) => {
	return (
		<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-5'>
			<div
				style={{ backgroundImage: `url(\'${data?.bgImgUrl}\')` }}
				className='w-full flex justify-center py-10 px-12 sm:px-20 md:px-28 lg:px-36'
			>
				<div className='min-w-full bg-white py-8 px-4 shadow sm:px-10'>
					<h2 className='text-center font-semibold text-primary-blue'>
						{data?.title}
					</h2>
					<div className='mt-3'>
						<div className='relative'>
							<div className='absolute inset-0 flex items-center'>
								<div className='w-full border-t border-gray-300' />
							</div>
							<div className='relative flex justify-center text-sm'>
								<span className='px-2 bg-white text-gray-400 text-lg'>
									<FontAwesomeIcon icon={faSnowflake} />
								</span>
							</div>
						</div>
					</div>
					<div className='mt-3'>
						<div
							dangerouslySetInnerHTML={{
								__html: data?.body,
							}}
						></div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default SecondSection;
