import { getSessionsListUrl } from './Constants';

export const fetchSessions = async () => {
  try {
    const response = await fetch(getSessionsListUrl());
    const data = await response.json();
    return data.sessions; // Assuming the response has a 'sessions' array
  } catch (error) {
    console.error('Error fetching sessions:', error);
    return [];
  }
};

export const getRandomSessionId = (sessions) => {
  const randomIndex = Math.floor(Math.random() * 100);
  return randomIndex // Ensure each session has either 'sessionId' or '_id' property
};

export const storeSessionId = (sessionId) => {
  localStorage.setItem('selectedSessionId', sessionId);
};

export const getSessionIdFromStorage = () => {
  return localStorage.getItem('selectedSessionId');
};

export const setDefaultSettingsForSession = (sessionId) => {
  const defaultSettings = {
    technicallyNecessary: true,
    socialEmbeds: true,
    display: true,
    mobileApp: true,
    statisticsRangeMeasurement: true,
    analysis: true,
    advertising: true,
    personalization: true,
  };

  Object.keys(defaultSettings).forEach(key => {
    localStorage.setItem(`${sessionId}-${key}`, defaultSettings[key]);
  });
};

export const getSettingsForSession = (sessionId) => {
  const settingsKeys = [
    'technicallyNecessary',
    'socialEmbeds',
    'display',
    'mobileApp',
    'statisticsRangeMeasurement',
    'analysis',
    'advertising',
    'personalization'
  ];

  const settings = {};
  settingsKeys.forEach(key => {
    settings[key] = localStorage.getItem(`${sessionId}-${key}`) === 'true';
  });

  return settings;
};

export const updateSettingForSession = (sessionId, key, value) => {
  localStorage.setItem(`${sessionId}-${key}`, value);
};
