import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure, Menu, Transition, Dialog } from '@headlessui/react';
import { MenuIcon, XIcon, ExclamationIcon } from '@heroicons/react/outline';
import { ALink } from '../common';
import { NAVBAR_URLS, NAVBAR_COMPONENT } from './data';
import { useLocation } from 'react-router-dom';
import { UserDesktop, UserMobile } from './user';

let EU_URL = process.env.REACT_APP_LIVE_EU_URL;
let US_URL = process.env.REACT_APP_LIVE_US_URL;
if (process.env.NODE_ENV === 'development') {
	EU_URL = process.env.REACT_APP_LOCAL_EU_URL;
	US_URL = process.env.REACT_APP_LOCAL_US_URL;
}

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function Header() {
	const location = useLocation();
	const { navigation, region } = NAVBAR_COMPONENT;
	const [open, setOpen] = useState(false);
	const [rg, setRg] = useState('');
	const [filteredNavigation, setFilteredNavigation] = useState(null);

	const currentUrl = window.location.host;

	const changeRegion = (value) => {
		setRg(value);
	};

	const redirectRegion = () => {
		if (rg === 'us') {
			window.location.replace(US_URL);
		}

		if (rg === 'eu') {
			window.location.replace(EU_URL);
		}
	};

	const filterNavigation = (items, region) => {
    return items.reduce((filtered, item) => {
        let itemType = item.type;

        if (item.children && item.children.length > 0) {
            const childTypes = item.children.map(child => child.type);
            if (childTypes.includes("US")) {
                itemType = "US";
            }
        }

        if (item.children && item.children.length > 0) {
            const filteredChildren = filterNavigation(item.children, region);
            if (itemType === "BOTH" || itemType === region || filteredChildren.length > 0) {
                filtered.push({
                    ...item,
                    children: filteredChildren,
                });
            }
        } else {
            if (itemType === "BOTH" || itemType === region) {
                filtered.push(item);
            }
        }
        return filtered;
    }, []);
};

	useEffect(() => {
		if (currentUrl.includes("localhost:3300") 
		|| currentUrl.includes("aidandang.firebase") 
		|| currentUrl.includes("us.adagiomedical.com")
		|| currentUrl.includes("adagio-us-dev.web.app")
	) {
			setFilteredNavigation(filterNavigation(navigation, "US"));
		} else {
			setFilteredNavigation(filterNavigation(navigation, "UK"));
		}
	}, []);

	return (
		<>
			<Transition.Root show={open} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
					</Transition.Child>

					<div className='fixed z-10 inset-0 overflow-y-auto'>
						<div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
								enterTo='opacity-100 translate-y-0 sm:scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 translate-y-0 sm:scale-100'
								leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
							>
								<Dialog.Panel className='relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6'>
									<div className='hidden absolute top-0 right-0 pt-4 pr-4'>
										<button
											type='button'
											className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
											onClick={() => setOpen(false)}
										>
											<span className='sr-only'>Close</span>
											<XIcon className='h-6 w-6' aria-hidden='true' />
										</button>
									</div>
									<div className='sm:flex sm:items-start'>
										<div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10'>
											<ExclamationIcon
												className='h-6 w-6 text-green-600'
												aria-hidden='true'
											/>
										</div>
										<div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
											<Dialog.Title
												as='h3'
												className='text-lg leading-6 font-medium text-secondary-blue'
											>
												YOU ARE ABOUT TO ENTER ANOTHER ADAGIO MEDICAL COUNTRY OR
												REGION SPECIFIC WEBSITE
											</Dialog.Title>
											<div className='mt-2'>
												<p className='text-sm text-gray-500 mt-5'>
													Please be ware that the website you have requested is
													for the residents of a particular country or region,
													as noted on that site. As a result, the site may
													contain information on phamaceuticals, medical devices
													and other products or uses of those products that are
													not approved in other countries or regions.
												</p>
												<p className='text-lg leading-6 font-medium text-secondary-blue mt-5'>
													DO YOU WISH TO CONTINUE?
												</p>
											</div>
										</div>
									</div>
									<div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
										<button
											type='button'
											className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-blue text-base font-medium text-white hover:bg-secondary-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
											onClick={(e) => {
												e.preventDefault();
												setOpen(false);
												redirectRegion();
											}}
										>
											Yes
										</button>
										<button
											type='button'
											className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'
											onClick={(e) => {
												e.preventDefault();
												setOpen(false);
											}}
										>
											No
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
			<Disclosure as='header' className='bg-white shadow'>
				{({ open }) => (
					<>
						<div className='max-w-7xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8'>
							<div className='relative h-24 flex justify-between'>
								<div className='relative z-10 px-2 flex lg:px-0'>
									<div className='flex-shrink-0 items-center hidden lg:flex text-sm decoration-2'>
										{!window.location.href.includes(EU_URL) ? (
											<>
												<span className='block font-medium text-gray-400 underline underline-offset-2 decoration-blue-300'>
													{region.us.desktopText}
												</span>
												<span className='block mx-2'>{'|'}</span>
												<ALink
													href={region.eu.link}
													onClick={(e) => {
														e.preventDefault();
														changeRegion('eu');
														setOpen(true);
													}}
												>
													{region.eu.desktopText}
												</ALink>
											</>
										) : (
											<>
												<ALink
													href={region.us.link}
													onClick={(e) => {
														e.preventDefault();
														changeRegion('us');
														setOpen(true);
													}}
												>
													{region.us.desktopText}
												</ALink>
												<span className='block mx-2'>{'|'}</span>
												<span className='block font-medium text-gray-400 underline underline-offset-2 decoration-blue-300'>
													{region.eu.desktopText}
												</span>
											</>
										)}
									</div>
									<div className='flex-shrink-0 items-center lg:hidden flex text-sm decoration-2'>
										{!window.location.href.includes(EU_URL) ? (
											<>
												<span className='block font-medium text-gray-400 underline underline-offset-2 decoration-blue-300'>
													{region.us.mobileText}
												</span>
												<span className='block mx-2'>{'|'}</span>
												<ALink
													href={region.eu.link}
													onClick={(e) => {
														e.preventDefault();
														changeRegion('eu');
														setOpen(true);
													}}
												>
													{region.eu.mobileText}
												</ALink>
											</>
										) : (
											<>
												<ALink
													href={region.us.link}
													onClick={(e) => {
														e.preventDefault();
														changeRegion('us');
														setOpen(true);
													}}
												>
													{region.us.mobileText}
												</ALink>
												<span className='block mx-2'>{'|'}</span>
												<span className='block font-medium text-gray-400 underline underline-offset-2 decoration-blue-300'>
													{region.eu.mobileText}
												</span>
											</>
										)}
									</div>
								</div>
								<div className='relative z-0 flex-1 px-2 flex items-center justify-center sm:absolute sm:inset-0'>
									<img
										className='block h-14 w-auto'
										src={NAVBAR_URLS.logo}
										alt='Workflow'
									/>
								</div>
								<div className='relative z-10 flex items-center lg:hidden'>
									{/* Mobile menu button */}
									<Disclosure.Button className='rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-400'>
										<span className='sr-only'>Open menu</span>
										{open ? (
											<XIcon className='block h-6 w-6' aria-hidden='true' />
										) : (
											<MenuIcon className='block h-6 w-6' aria-hidden='true' />
										)}
									</Disclosure.Button>
								</div>

								<UserDesktop />
							</div>
							<nav
								className='hidden lg:py-2 lg:flex lg:space-x-8 flex justify-center'
								aria-label='Global'
							>
								{filteredNavigation?.map((item) => (
									<div
										className='relative z-10 flex items-center'
										key={item.name}
									>
										{item.children ? (
											<Menu as='div' className='flex-shrink-0 relative'>
												<div>
													<Menu.Button
														className={classNames(
															location.pathname.includes(item.parent)
																? 'underline underline-offset-4 decoration-2 decoration-blue-300 hover:decoration-blue-400'
																: 'hover:bg-blue-50',
															'rounded-md py-1 px-3 inline-flex items-center text-xs font-medium text-gray-600 hover:text-primary-blue'
														)}
													>
														<span className='sr-only'>Open user menu</span>
														{item.name}
													</Menu.Button>
												</div>
												<Transition
													as={Fragment}
													enter='transition ease-out duration-100'
													enterFrom='transform opacity-0 scale-95'
													enterTo='transform opacity-100 scale-100'
													leave='transition ease-in duration-75'
													leaveFrom='transform opacity-100 scale-100'
													leaveTo='transform opacity-0 scale-95'
												>
													<Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none'>
														{item.children.map((child) => (
															<Menu.Item key={child.name}>
																{({ active }) => (
																	<Link
																		to={child.href}
																		state={
																			child.tab ? { tab: child.tab } : null
																		}
																		className={classNames(
																			active ? 'bg-gray-100' : '',
																			'block py-2 px-4 text-xs text-gray-600 hover:bg-primary-blue hover:text-gray-50'
																		)}
																	>
																		{child.name}
																	</Link>
																)}
															</Menu.Item>
														))}
													</Menu.Items>
												</Transition>
											</Menu>
										) : (
											<Link
												to={item.href}
												className={classNames(
													item.href === location.pathname
														? 'underline underline-offset-4 decoration-2 decoration-blue-300 hover:decoration-blue-400'
														: 'hover:bg-blue-50',
													'rounded-md py-1 px-3 inline-flex items-center text-xs text-gray-600 font-medium hover:text-primary-blue'
												)}
											>
												{item.name}
											</Link>
										)}
									</div>
								))}
							</nav>
						</div>

						<Disclosure.Panel
							as='nav'
							className='lg:hidden'
							aria-label='Global'
						>
							<div className='pt-2 pb-3 px-2 space-y-1'>
								{filteredNavigation?.map((item) => (
									<div key={item.name}>
										{item.children ? (
											<>
												<Disclosure>
													<Disclosure.Button
														key={item.name}
														as='a'
														href={item.href}
														className={classNames(
															location.pathname.includes(item.parent)
																? 'bg-gray-100 text-gray-900'
																: 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
															'block rounded-md py-2 px-3 text-base font-medium flex'
														)}
														aria-current={
															location.pathname.includes(item.parent)
																? 'page'
																: undefined
														}
													>
														<span className='flex-1'>{item.name}</span>
														<svg
															className='text-gray-300 ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
															viewBox='0 0 20 20'
															aria-hidden='true'
														>
															<path
																d='M6 6L14 10L6 14V6Z'
																fill='currentColor'
															/>
														</svg>
													</Disclosure.Button>

													<Disclosure.Panel className={'px-3'}>
														{item.children.map((child) => (
															<a
																key={child.name}
																href={child.href}
																className={classNames(
																	child.href === location.pathname
																		? 'text-primary-blue'
																		: 'text-gray-600',
																	'group w-full flex items-center pl-3 pr-2 py-2 text-sm font-medium rounded-md hover:text-gray-900 hover:bg-gray-50'
																)}
															>
																{child.name}
															</a>
														))}
													</Disclosure.Panel>
												</Disclosure>
											</>
										) : (
											<Disclosure.Button
												key={item.name}
												as='a'
												href={item.href}
												className={classNames(
													item.href === location.pathname
														? 'bg-gray-100 text-gray-900'
														: 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
													'block rounded-md py-2 px-3 text-base font-medium flex'
												)}
												aria-current={
													item.href === location.pathname ? 'page' : undefined
												}
											>
												{item.name}
											</Disclosure.Button>
										)}
									</div>
								))}
							</div>

							<UserMobile />
						</Disclosure.Panel>
					</>
				)}
			</Disclosure>
		</>
	);
}
