/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Field } from "react-final-form";
import {
  FieldSet,
  Subtitle,
  TitleSet,
  Card,
  Input,
  FORM_GRID,
  Checkbox,
} from "../inputs";
import FileInput from "../FileInput";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import PrivacyPolicyModal from "../../../pages/PrivacyPolicyModal";

let EU_URL = process.env.REACT_APP_LIVE_EU_URL;
if (process.env.NODE_ENV === "development") {
  EU_URL = process.env.REACT_APP_LOCAL_EU_URL;
}

export default function Fields({ values }) {
  let region = "US";
  if (window.location.href.includes(EU_URL)) {
    region = "EU";
  }

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  }

  return (
    <>
      <FieldSet>
        <TitleSet>
          <Subtitle>
            <span className="font-medium text-xl text-gray-700">
              {region === "US" ? "United States" : "Europe"}
            </span>
          </Subtitle>
        </TitleSet>
        <Card>
          <div className="col-span-6 divide-y">
            <div className="mb-5">
              <p className="mt-3 text-lg leading-6 font-bold text-gray-700">
                Worldwide Headquarters
              </p>
              <dl className="mt-8 text-base text-gray-500">
                <div>
                  <dt className="sr-only">Postal address</dt>
                  <dd>
                    <p>26051 Merit Cir #102</p>
                    <p>Laguna Hills, CA 92653</p>
                  </dd>
                </div>
                <div className="mt-6">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <PhoneIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">+1 (949) 348 1188</span>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <MailIcon
                      className="flex-shrink-0 h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3 font-medium text-gray-700">
                      web_request@adagiomedical.com
                    </span>
                  </dd>
                </div>
              </dl>
              <p className="mt-6 text-base text-gray-500">
                Looking for careers?{" "}
                <a
                  href="/careers"
                  className="font-medium text-gray-700 underline"
                >
                  View all job openings
                </a>
                .
              </p>
            </div>
            {region === "EU" && (
              <div className="pt-3">
                <p className="mt-3 text-lg leading-6 text-gray-500 font-bold">
                  Adagio Medical GmbH
                </p>
                <dl className="mt-8 text-base text-gray-500">
                  <div>
                    <dt className="sr-only">Postal address</dt>
                    <dd>
                      <p>Freihausstrasse 33</p>
                      <p>83707 Bad Wiesse, Germany</p>
                    </dd>
                  </div>
                  <div className="mt-6">
                    <dt className="sr-only">Phone number</dt>
                    <dd className="flex">
                      <PhoneIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">+(49) 8022 509 306 0</span>
                    </dd>
                  </div>
                  <div className="mt-3">
                    <dt className="sr-only">Email</dt>
                    <dd className="flex">
                      <MailIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3 font-medium text-gray-700">
                        {region === "US"
                          ? "	web_request@adagiomedical.com"
                          : "	web_request_eu@adagiomedical.com"}
                      </span>
                    </dd>
                  </div>
                </dl>
                <p className="mt-6 text-base text-gray-500">
                  Looking for careers?{" "}
                  <a
                    href="/careers"
                    className="font-medium text-gray-700 underline"
                  >
                    View all job openings
                  </a>
                  .
                </p>
              </div>
            )}
          </div>
        </Card>
      </FieldSet>
      <FieldSet>
        <TitleSet>
          <Subtitle>
            <span className="font-medium text-xl text-gray-700">
              Applicant Information
            </span>
          </Subtitle>
        </TitleSet>
        <Card>
          <Field name="firstName">
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label="First name *"
                type="text"
                size={FORM_GRID[3]}
                placeholder=""
                autoComplete="off"
                required={true}
                disabled={false}
              ></Input>
            )}
          </Field>
          <Field name="lastName">
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label="Last name *"
                type="text"
                size={FORM_GRID[3]}
                placeholder=""
                autoComplete="off"
                required={true}
                disabled={false}
              ></Input>
            )}
          </Field>
          <Field name="position">
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label="Position *"
                type="text"
                size={FORM_GRID[3]}
                placeholder=""
                autoComplete="off"
                required={true}
                disabled={false}
              ></Input>
            )}
          </Field>
          <Field name="address">
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label="Address *"
                type="text"
                size={FORM_GRID[3]}
                placeholder=""
                autoComplete="off"
                required={true}
                disabled={false}
              ></Input>
            )}
          </Field>
          <Field name="email">
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label="Email *"
                type="email"
                size={FORM_GRID[3]}
                placeholder=""
                autoComplete="off"
                required={true}
                disabled={false}
              ></Input>
            )}
          </Field>
          <Field name="phone">
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label="Phone *"
                type="text"
                size={FORM_GRID[3]}
                placeholder=""
                autoComplete="off"
                required={true}
                disabled={false}
              ></Input>
            )}
          </Field>

          <div className="sr-only">
            <Field name="resumeUrl">
              {({ input, meta }) => (
                <Input
                  input={input}
                  meta={meta}
                  type="text"
                  autoComplete="off"
                  size={FORM_GRID[0]}
                >
                  Resume Url
                </Input>
              )}
            </Field>
          </div>

          <FileInput
            title="Resume *"
            folder={"resumes"}
            id="resume"
            required={true}
          />

          <Field name="privacyPolicy" type="checkbox">
              {({ input, meta }) => (
                <Checkbox
                  input={input}
                  meta={meta}
                  size={FORM_GRID[0]}
                  disabled={false}
                  required={true}
                >
                  I agree to the{" "}
                  <a className="underline text-sky-500" rel="noreferrer" href="#" onClick={() => setShowModal(true)}>
                    privacy policy
                  </a>{" "}
                  as part of the application process
                </Checkbox>
              )}
          </Field>
        </Card>
      </FieldSet>
      <PrivacyPolicyModal showModal={showModal} closeModal={handleCloseModal} />
    </>
  );
}
