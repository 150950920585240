import React, { useState, useEffect } from 'react';
import Hero from '../components/hero';
import SecondSection from '../components/second-section';
import FourCellSection from '../components/four-cell-section';
import BottomSection from '../components/bottom-section';
import { getPageId } from '../helpers/api-utils';
import Spinner from '../components/spinner';

let EU_URL = process.env.REACT_APP_LIVE_EU_URL;
if (process.env.NODE_ENV === 'development') {
	EU_URL = process.env.REACT_APP_LOCAL_EU_URL;
}

export default function Home() {
	let pathname = 'home-pages/624f312cca206f3ae50444be';
	if (window.location.href.includes(EU_URL)) {
		pathname = 'home-pages/62853005ad96ea8288aaeb45';
	}

	const [data, setData] = useState(null);

	useEffect(() => {
		const resObjName = 'homePage';
		getPageId(pathname, resObjName, setData);
	}, []);
	return (
		<>
			{data ? (
				<>
					<Hero data={data.hero} />
					<SecondSection data={data.second} />
					<FourCellSection data={data.fourcells} />
					<BottomSection data={data.bottom} />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
}
