import React, { useState, useEffect } from 'react';
import Hero from '../components/hero';
import SecondWithTabSection from '../components/second-with-tab-section';
import FourCellSection from '../components/four-cell-section';
import { getPage } from '../helpers/api-utils';
import Spinner from '../components/spinner';

let EU_URL = process.env.REACT_APP_LIVE_EU_URL;
if (process.env.NODE_ENV === 'development') {
	EU_URL = process.env.REACT_APP_LOCAL_EU_URL;
}

export default function TechnologyPfca() {
	let region = 'US';
	if (window.location.href.includes(EU_URL)) {
		region = 'EU';
	}

	const [data, setData] = useState(null);

	useEffect(() => {
		const apiPath = 'technology-pages';
		const resObjName = 'technologyPages';
		getPage(apiPath, resObjName, setData, region);
	}, []);
	return (
		<>
			{data ? (
				<>
					<Hero data={data.hero} />
					<SecondWithTabSection data={data.second} page={'PFCA'} />
					<FourCellSection data={data.fourcells} />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
}
