import React, { useState, useEffect } from 'react';
import Hero from '../components/hero';
import SecondSection from '../components/second-section';
import TwoCellSection from '../components/two-cell-section';
import { getPageId } from '../helpers/api-utils';
import Spinner from '../components/spinner';

let EU_URL = process.env.REACT_APP_LIVE_EU_URL;
if (process.env.NODE_ENV === 'development') {
	EU_URL = process.env.REACT_APP_LOCAL_EU_URL;
}

export default function Patients() {
	let pathname = 'patients-pages/62506520c7dc6249dc12e120';
	if (window.location.href.includes(EU_URL)) {
		pathname = 'patients-pages/62993312d77314bcc78f2d7b';
	}

	const [data, setData] = useState(null);

	useEffect(() => {
		const resObjName = 'patientsPage';
		getPageId(pathname, resObjName, setData);
	}, []);
	return (
		<>
			{data ? (
				<>
					<Hero data={data.hero} />
					<SecondSection data={data.second} />
					<TwoCellSection data={data.twocells} />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
}
