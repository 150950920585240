import React from 'react';
import FormSubmit from '../FormSubmit';
import Fields from './Fields';
import { sendApply } from '../../../store/slices/authSlice';
import { useSelector } from 'react-redux';

const getReqBody = (values, file) => {
	return {
		firstName: values.firstName,
		lastName: values.lastName,
		position: values.position,
		address: values.address,
		email: values.email,
		phone: values.phone,
		resume: file ? file.resume : '',
		privacyPolicy: values.privacyPolicy
	};
};
const buttonLabel = 'Submit';
const redirectPath = '';

const ApplyForm = ({ setRedirectPath }) => {
	const file = useSelector((state) => state.file);

	return (
		<FormSubmit
			component={Fields}
			getReqBody={getReqBody}
			submitAction={sendApply}
			buttonLabel={buttonLabel}
			redirectPath={redirectPath}
			setRedirectPath={setRedirectPath}
			file={file}
		/>
	);
};

export default ApplyForm;
