import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getCorporates } from '../../helpers/api-utils';


const GovernanceComponent = () => {
  const currentUrl = window.location.host;
  const [data, setData] = useState(null);

  useEffect(() => {
    const apiPath = 'corporates';
    const resObjName = 'corporates';

    let search = ""
    if (currentUrl.includes("localhost:3300") 
			|| currentUrl.includes("aidandang.firebase") 
			|| currentUrl.includes("us.adagiomedical.com")
			|| currentUrl.includes("adagio-us-dev.web.app")
		) {
      search = "US";
		} else {
      search = "UK";
		}

    getCorporates(apiPath, resObjName, setData, search);
  }, []);

  const filterDocumentsByType = (documents, type) => {
    return documents?.filter((document) => document.type === type);
  };

  const categorizedDocuments = {
    'Governance Documents': filterDocumentsByType(data, 'Governance Documents'),
    'Committee Charters': filterDocumentsByType(data, 'Committee Charters'),
  };

  return (
    <div className="container mx-auto mt-8 ml-8 flex flex-col gap-4">
      {Object.entries(categorizedDocuments).map(([category, categoryDocuments]) => (
        categoryDocuments?.length > 0 && (
          <div key={category} className="bg-white p-4 rounded-lg flex-1 border-none">
            <h2 className="text-xl font-light mb-4">{category}</h2>
            <ul className="flex flex-col gap-2 list-none">
              {categoryDocuments.map((document, index) => (
                <li key={index} className="flex items-center mb-2 border-none">
                  {document.mime === 'doc' && (
                    <a
                      href={document.url}
                      download
                      className="flex items-center text-blue-300"
                    >
                      <FontAwesomeIcon icon="fa fa-file-word fa-xs" className="mr-2" style={{ color: 'rgb(62, 124, 192)' }} />
                      {document.title}
                    </a>
                  )}
  
                  {document.mime === 'pdf' && (
                    <a
                      href={document.url}
                      download
                      className="flex items-center text-blue-300"
                    >
                      <FontAwesomeIcon icon="fa fa-file-pdf fa-xs" className="mr-2" style={{ color: '#b54f4f' }} />
                      {document.title}
                    </a>
                  )}

                  {document.mime === 'excel' && (
                    <a
                      href={document.url}
                      download
                      className="flex items-center text-blue-300"
                    >
                      <FontAwesomeIcon icon="fa fa-file-excel fa-xs" className="mr-2" style={{ color: '#47ae53' }} />
                      {document.title}
                    </a>
                  )}
  
                </li>
              ))}
            </ul>
          </div>
        )
      ))}
    </div>
  );
};

export default GovernanceComponent;
