import React, { useState } from 'react';
import RegisterForm from '../lib/form/register';
import FormTitle from '../lib/form/FormTitle';

const initialState = '';

const Register = () => {
	const [redirectPath, setRedirectPath] = useState(initialState);

	return (
		<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
			{redirectPath ? (
				<div>
					<h3 className='text-lg leading-6 font-medium text-gray-900'>
						Your registration form has been submitted.
					</h3>
					<p className='my-5 text-sm'>
						You should receive your email confirmation shortly at{' '}
						<span className='font-medium'>{redirectPath}</span>. Please verify
						the email to activate your account.
					</p>
				</div>
			) : (
				<>
					<FormTitle title='Registration' />
					<RegisterForm setRedirectPath={setRedirectPath} />
				</>
			)}
		</main>
	);
};

export default Register;
