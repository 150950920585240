/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Spinner from "../components/spinner";
import InvestorSection from "../components/investor-section";
import { getPageId } from "../helpers/api-utils";
import HeroTwo from "../components/hero/index-two";
import { useLocation } from "react-router-dom";

export default function Investor() {
    const location = useLocation();
    const pathname = location.pathname;
    const [data, setData] = useState(null);
    const [selectedHeroIndex, setSelectedHeroIndex] = useState(0);

    const fakeData = {
        "second": {
            "title": "A LEADING INNOVATOR IN TREATMENT OF CARDIAC ARRHYTHMIAS",
            "body": "Adagio Medical, Inc., is a privately held medical device company located in Laguna Hills, California. Co-founded in 2011 by Dr. James Cox, the inventor of “gold standard” Cox-Maze procedure, and Olav Bergheim, a serial entrepreneur, Adagio Medical is developing innovative ablation technologies for the treatment of cardiac arrhythmias including atrial fibrillation, atrial flutter, and ventricular tachycardia. Adagio’s unique portfolio is based on Ultra-Low Temperature Cryoablation (ULTC) and Pulsed-Field Cryoablation (PFCA) designed to produce durable, contiguous, transmural lesions anywhere in the heart.",
            "bgImgUrl": "https://firebasestorage.googleapis.com/v0/b/cms-server-69a05.appspot.com/o/about%2Fadagio-60.jpg?alt=media&token=a04ac88b-db37-4943-92b9-70002b99c2f2",
            "_id": "657030c2931255b75ca0ff99"
        },
    }

    const handleHeroDataChange = (index) => {
        setSelectedHeroIndex(index);
    };

    useEffect(() => {
        const pathname = 'investor-resources';
        const resObjName = 'investorResources';
        getPageId(pathname, resObjName, setData);
    }, []);
    
    return (
        <>
            {fakeData ? (
                <>
                    <HeroTwo data={data} pathname={pathname} selectedHeroIndex={selectedHeroIndex}  />
                    <InvestorSection data={fakeData.second} onHeroDataChange={handleHeroDataChange} selectedHeroIndex={selectedHeroIndex} />
                </>
            ) : (
                <Spinner />
            )}
        </>
    )
}