import React, { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { BellIcon, LoginIcon, UserIcon } from '@heroicons/react/outline';
import { useSelector, useDispatch } from 'react-redux';
import { signOut } from '../../store/slices/authSlice';

import { NAVBAR_COMPONENT } from './data';
import { ALink } from '../common';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export const UserMobile = () => {
	const { userNavigation, guestNavigation } = NAVBAR_COMPONENT;
	const currentUser = useSelector((state) => state.auth.currentUser);

	const accessToken = localStorage.getItem('accessToken');
	const dispatch = useDispatch();

	return (
		<div className='border-t border-gray-200 pt-4 pb-3'>
			{(currentUser || accessToken) ? (
				<>
					<div className='px-4 flex items-center'>
						<div className='flex-shrink-0'>
							<div className='mx-auto flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10'>
								<UserIcon
									className='h-6 w-6 text-primary-blue'
									aria-hidden='true'
								/>
							</div>
						</div>
						<div className='ml-3'>
							<div className='text-base font-medium text-gray-800'>
								Hello, Member
							</div>
						</div>
						<button
							type='button'
							className='ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
						>
							<span className='sr-only'>View notifications</span>
							<BellIcon className='h-6 w-6' aria-hidden='true' />
						</button>
					</div>
					<div className='mt-3 px-2 space-y-1'>
						{userNavigation.map((item) => (
							<Disclosure.Button
								key={item.name}
								onClick={
									item.name === 'Sign out' &&
									((e) => {
										dispatch(signOut());
									})
								}
								as='a'
								href={item.href}
								className='block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900'
							>
								{item.name}
							</Disclosure.Button>
						))}
					</div>
				</>
			) : (
				<>
					<div className='px-4 flex items-center'>
						<div className='flex-shrink-0'>
							<span className='bg-gray-100'>
								<svg
									className='h-10 w-10 text-gray-300 rounded-full'
									fill='currentColor'
									viewBox='0 0 24 24'
								>
									<path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
								</svg>
							</span>
						</div>
						<div className='ml-3'>
							<div className='text-base font-medium text-gray-800'>
								Hello, Guest
							</div>
						</div>
						<button
							type='button'
							className='ml-auto flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
						>
							<span className='sr-only'>View notifications</span>
							<LoginIcon className='h-6 w-6' aria-hidden='true' />
						</button>
					</div>
					<div className='mt-3 px-2 space-y-1'>
						{guestNavigation.map((item) => (
							<Disclosure.Button
								key={item.name}
								as='a'
								href={item.href}
								className='block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900'
							>
								{item.name}
							</Disclosure.Button>
						))}
					</div>
				</>
			)}
		</div>
	);
};

export const UserDesktop = () => {
	const { userNavigation } = NAVBAR_COMPONENT;
	// const currentUser = useSelector((state) => state.auth.currentUser);
	// get local storage
	const accessToken = localStorage.getItem('accessToken');
	const dispatch = useDispatch();

	return (
		<div className='hidden lg:relative lg:z-20 lg:ml-4 lg:flex lg:items-center'>
			{accessToken ? (
				<>
					{' '}
					<button
						type='button'
						className='flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400'
					>
						<span className='sr-only'>View notifications</span>
						<BellIcon className='h-6 w-6' aria-hidden='true' />
					</button>
					{/* Profile dropdown */}
					<Menu as='div' className='flex-shrink-0 relative ml-4'>
						<div>
							<Menu.Button className='bg-blue-100 h-10 w-10 rounded-full flex mx-auto flex-shrink-0 items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400'>
								<span className='sr-only'>Open user menu</span>
								<UserIcon className='h-6 w-6 text-primary-blue' />
							</Menu.Button>
						</div>
						<Transition
							as={Fragment}
							enter='transition ease-out duration-100'
							enterFrom='transform opacity-0 scale-95'
							enterTo='transform opacity-100 scale-100'
							leave='transition ease-in duration-75'
							leaveFrom='transform opacity-100 scale-100'
							leaveTo='transform opacity-0 scale-95'
						>
							<Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none'>
								{userNavigation.map((item) => (
									<Menu.Item key={item.name}>
										{({ active }) => (
											<a
												href={item.href}
												onClick={
													item.name === 'Sign out' &&
													((e) => {
														dispatch(signOut());
													})
												}
												className={classNames(
													active ? 'bg-gray-100' : '',
													'block py-2 px-4 text-sm text-gray-700'
												)}
											>
												{item.name}
											</a>
										)}
									</Menu.Item>
								))}
							</Menu.Items>
						</Transition>
					</Menu>
				</>
			) : (
				<>
					<button
						type='button'
						className='flex-shrink-0 bg-white rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400'
					>
						<span className='sr-only'>View notifications</span>
						<LoginIcon className='h-6 w-6' aria-hidden='true' />
					</button>
					<div className='flex-shrink-0 relative ml-4 text-sm'>
						<ALink href='/sign-in'>Sign in</ALink>
					</div>
				</>
			)}
		</div>
	);
};
