import React, { useState, useEffect } from 'react';
import { UserIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import { getProfile } from '../helpers/api-utils';

export default function UserProfile() {
	let navigate = useNavigate();

	const [user, setUser] = useState(null);

	const sendToResetPassword = () => {
		navigate('/reset-password', { replace: true });
	};

	useEffect(() => {
		getProfile('profile', setUser);
	}, []);

	return (
		<>
			{user && (
				<main className='max-w-7xl py-8 px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
					<h1 className='text-primary-blue text-3xl pb-5'>User Profile</h1>
					<div className='mt-5'>
						{/* Description list with inline editing */}
						<div className='divide-y divide-gray-200'>
							<div className='space-y-1'>
								<h3 className='text-lg leading-6 font-medium text-gray-900'>
									Account Information
								</h3>
							</div>
							<div className='mt-6'>
								<dl className='divide-y divide-gray-200'>
									<div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
										<dt className='text-sm font-medium text-gray-500'>Name</dt>
										<dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
											<span className='flex-grow'>
												{user.firstName} {user.lastName}
											</span>
											<span className='ml-4 flex-shrink-0'>
												<button
													type='button'
													className='font-medium text-gray-300'
												>
													Checked
												</button>
											</span>
										</dd>
									</div>
									<div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5'>
										<dt className='text-sm font-medium text-gray-500'>Photo</dt>
										<dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
											<span className='flex-grow'>
												<div className='mx-auto flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-full bg-blue-100 sm:mx-0 sm:h-8 sm:w-8'>
													<UserIcon
														className='h-6 w-6 text-primary-blue'
														aria-hidden='true'
													/>
												</div>
											</span>
											<span className='ml-4 flex-shrink-0'>
												<button
													type='button'
													className='font-medium text-gray-300'
												>
													Checked
												</button>
											</span>
										</dd>
									</div>
									<div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5'>
										<dt className='text-sm font-medium text-gray-500'>Email</dt>
										<dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
											<span className='flex-grow'>{user.email}</span>
											<span className='ml-4 flex-shrink-0'>
												<button
													type='button'
													className='font-medium text-gray-300'
												>
													Checked
												</button>
											</span>
										</dd>
									</div>
									<div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5'>
										<dt className='text-sm font-medium text-gray-500'>
											Password
										</dt>
										<dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
											<span className='flex-grow'>************</span>
											<span className='ml-4 flex-shrink-0'>
												<button
													onClick={(e) => {
														e.preventDefault();
														sendToResetPassword();
													}}
													type='button'
													className='rounded-md font-medium text-primary-blue hover:text-secondary-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:primary-blue'
												>
													Change Password
												</button>
											</span>
										</dd>
									</div>
									<div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5'>
										<dt className='text-sm font-medium text-gray-500'>
											Account Type
										</dt>
										<dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
											<span className='flex-grow'>
												{user.receiving === 'standard'
													? 'Standard'
													: 'Professional'}
											</span>
											<span className='ml-4 flex-shrink-0'>
												<button
													type='button'
													className='font-medium text-gray-300'
												>
													Checked
												</button>
											</span>
										</dd>
									</div>
									<div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200'>
										<dt className='text-sm font-medium text-gray-500'>
											Address
										</dt>
										<dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
											<span className='flex-grow'>
												{user.city}, {user.postalCode}, {user.state},{' '}
												{user.countryCode}{' '}
											</span>
											<span className='ml-4 flex-shrink-0'>
												<button
													type='button'
													className='font-medium text-gray-300'
												>
													Checked
												</button>
											</span>
										</dd>
									</div>
								</dl>
							</div>
						</div>

						<div className='mt-10 divide-y divide-gray-200'>
							<div className='space-y-1'>
								<h3 className='text-lg leading-6 font-medium text-gray-900'>
									Professional Information
								</h3>
							</div>
							<div className='mt-6'>
								<dl className='divide-y divide-gray-200'>
									<div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4'>
										<dt className='text-sm font-medium text-gray-500'>
											Professional Category
										</dt>
										<dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
											<span className='flex-grow'>{user.professionalCat}</span>
											<span className='ml-4 flex-shrink-0'>
												<button
													type='button'
													className='font-medium text-gray-300'
												>
													Checked
												</button>
											</span>
										</dd>
									</div>
									<div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5'>
										<dt className='text-sm font-medium text-gray-500'>
											Primary Specialty
										</dt>
										<dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
											<span className='flex-grow'>{user.primarySpecialty}</span>
											<span className='ml-4 flex-shrink-0'>
												<button
													type='button'
													className='font-medium text-gray-300'
												>
													Checked
												</button>
											</span>
										</dd>
									</div>
									<div className='py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5'>
										<dt className='text-sm font-medium text-gray-500'>
											Primary affiliation/employer
										</dt>
										<dd className='mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
											<span className='flex-grow'>{user.primaryHospital}</span>
											<span className='ml-4 flex-shrink-0'>
												<button
													type='button'
													className='font-medium text-gray-300'
												>
													Checked
												</button>
											</span>
										</dd>
									</div>
								</dl>
							</div>
						</div>
					</div>
				</main>
			)}
		</>
	);
}
