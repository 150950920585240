import React, { useState } from 'react';
import ContactForm from '../lib/form/contact';
import FormTitle from '../lib/form/FormTitle';

const initialState = '';

const Contact = () => {
	const [redirectPath, setRedirectPath] = useState(initialState);

	return (
		<main className='max-w-7xl px-4 sm:px-6 mx-auto pb-10 lg:py-12 lg:px-8'>
			{redirectPath ? (
				<div>
					<h3 className='text-lg leading-6 font-medium text-gray-900'>
						Thank you for your submission.
					</h3>
					<p className='my-5 text-sm'>We will contact to you soon!</p>
				</div>
			) : (
				<>
					<FormTitle title='Contact' />
					<ContactForm setRedirectPath={setRedirectPath} />
				</>
			)}
		</main>
	);
};

export default Contact;
