import React, {useState, useEffect} from "react";
import Hero from "../components/hero";

import { getPage } from "../helpers/api-utils";
import SecondMaterialsVclasSection from "../components/second-materials-vclas-section";

const region = process.env.REACT_APP_REGION;

export default function MarketingVClass() {
  let search = 'US';
  const [data, setData] = useState(null);

  useEffect(() => {
    if (window.location.href.includes(region)) {
      search = 'EU';
    }
    const apiPath = 'marketing-vclas-pages';
		const resObjName = 'materialsVClasPages';
		getPage(apiPath, resObjName, setData, search);
  }, []);
  return (
    <>
      {data && (
        <>
          <Hero data={data.hero}/>
          <SecondMaterialsVclasSection />
        </>
      )}
    </>
  )
}
