import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDocuments, getYearOptions } from "../../helpers/api-utils";
import { formatDateString } from "../../utils/convertTime";

const currentClass =
	'border-secondary-blue text-primary-blue border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium';
const normalClass =
	'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium';

const SecFiling = () => {
    const apiPath = 'document';
    const resObjName = 'documents';
    const currentUrl = window.location.host;

    const [data, setData] = useState(null);
    const [dataYear, setDataYear] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedFilingType, setSelectedFilingType] = useState(null);
    const [currentPositions, setCurrentPositions] = useState(null);
    const [region, setRegion] = useState("");

    const totalPage = data && data.pagination.totalPages;

    const onPageChange = (index) => {
        setCurrentPositions((prevPositions) => ({
            ...prevPositions,
            current: index,
        }));
        getDocuments(apiPath, resObjName, setData, selectedFilingType, selectedYear, index, region);
    }

    const getUniqueYears = () => {
        const uniqueYears = new Set(dataYear?.map((document) => new Date(document.filing_date).getFullYear()));
        const sortedYears = Array.from(uniqueYears).sort((a, b) => b - a);
        sortedYears.unshift('Year');
        
        return sortedYears;
    };

    const yearOptions = getUniqueYears(); 

    const filingTypeOptions = ["Type", "Annual Filings", "Quarterly Filings", "Current Filings", "Registration Statements", "Section 16",
    "Mergers & Acquisitions", "Proxy & Information Statements", "Other"];

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.slice(0, maxLength) + '...';
    }

    useEffect(() => {
        getDocuments(apiPath, resObjName, setData, selectedFilingType, selectedYear, currentPositions, region);

        setCurrentPositions({
            pages: Array.from({ length: parseInt(totalPage) }, (_, index) => index + 1),
            current: 0,
        })
    }, [selectedFilingType, selectedYear, totalPage, dataYear]);

    useEffect(() => {
        let search = ""
        if (currentUrl.includes("localhost:3300") 
          || currentUrl.includes("aidandang.firebase") 
          || currentUrl.includes("us.adagiomedical.com")
          || currentUrl.includes("adagio-us-dev.web.app")
        ) {
            search = "US";
            setRegion("US");
        } else {
            search = "EU";
            setRegion("EU");
        }

        getYearOptions(apiPath, resObjName, setDataYear);
        getDocuments(apiPath, resObjName, setData, selectedFilingType, selectedYear, currentPositions, search);
    }, []);

    return (
        <section className="container mx-auto flex flex-col gap-4">
            <p className="text-sm font-semibold mb-8 mt-6">
                The financial and other information contained in the documents that may be accessed on this page speaks only as of
                the date of those documents. The information could be out of date and no longer accurate. Adagio Medical does not
                undertake an obligation, and disclaims any duty, to update any of the information in those documents. Adagio
                Medical's future performance, including financial performance, is subject to various risks and uncertainties that could
                cause actual results to differ materially from expectations. The factors that could affect the company's future results
                are discussed more fully in our reports field with the SEC.
            </p>

            {/* Dropdown */}
            <div className="mt-4 bg-gray-100 p-4 flex flex-col md:flex-row md:items-center justify-between border border-gray-300">
                <div className="dropdown flex-grow mr-4 mb-4 md:mb-0">
                    <label className="text-sm text-black font-semibold ml-2">Type</label>
                    <select 
                        className="ml-2 w-full border border-gray-300"
                        value={selectedFilingType}
                        onChange={(e) => setSelectedFilingType(e.target.value)}
                    >
                        {filingTypeOptions.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="dropdown flex-grow mx-4 mb-4 md:mb-0">
                    <label className="text-sm text-black font-semibold ml-2">Year</label>
                    <select 
                        className="ml-2 w-full border border-gray-300"
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                    >
                        {yearOptions.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>

                <div 
                    className="ml-4 mb-4 cursor-pointer flex flex-col items-center"
                    onClick={() => {
                        setSelectedYear("Year");
                        setSelectedFilingType("Type");
                    }}
                >
                    <label className="text-sm text-black font-semibold ml-2">Clear Filters</label>
                    <FontAwesomeIcon className="mt-2" icon="fa-solid fa-rotate-left" />
                </div>
            </div>

            {/* Table */}
            <div className="mt-4 overflow-x-auto">
                <table className="table-auto w-full">
                    <thead>
                        <tr>
                            <th className="text-sm text-left px-4 py-2">Form</th>
                            <th className="text-sm text-left px-4 py-2">Description</th>
                            <th className="text-sm text-left px-4 py-2">Filing Date</th>
                            <th className="text-sm text-left px-4 py-2">Format</th>
                            <th className="px-4 py-2"></th>
                        </tr>
                    </thead>
                    <tbody className="bg-gray-100">
                        {data && data[resObjName].length > 0 ? (
                            data[resObjName].map((document, index) => (
                                <tr key={index} className="border-t border-b">
                                    <td className="px-4 py-2 text-blue-300">{document.form}</td>
                                    <td className="px-4 py-2">{truncateText(document.description, 40)}</td>
                                    <td className="px-4 py-2">{formatDateString(document.filing_date)}</td>
                                    <td className="px-4 py-2">
                                        <div className="flex flex-row">
                                            {document.mime1 === 'pdf' && (
                                                <a className="mr-2" href={document.url1} target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon="fa fa-file-pdf fa-xs" className="mr-2" style={{ color: '#b54f4f' }} />
                                                </a>
                                            )}
                                            {document.mime3 === 'doc' && (
                                                <a className="mr-2" href={document.url3} target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon="fa fa-file-word fa-xs" className="mr-2" style={{ color: 'rgb(62, 124, 192)' }} />
                                                </a>
                                            )}
                                            {document.mime2 === 'excel' && (
                                                <a className="mr-2" href={document.url2} target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon="fa fa-file-excel fa-xs" className="mr-2" style={{ color: '#47ae53' }} />
                                                </a>
                                            )}
                                        </div>
                                    </td>
                                    <td className="px-2 py-2" style={{ verticalAlign: "middle" }}>
                                        {document.link_sec && (
                                            <a className="mr-2" href={document.link_sec} target="_blank" rel="noreferrer">
                                                <FontAwesomeIcon icon="fa-solid fa-link" />
                                            </a>
                                        )}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="py-8 text-center">
                                    <p>No data</p>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className="mt-4 flex justify-center">
                <div className="hidden md:-mt-px md:flex">
                    {currentPositions?.pages &&
                        currentPositions.pages.map((el, index) => (
                            <a
                                key={index}
                                onClick={() => onPageChange(index)}
                                className={index === currentPositions.current ? currentClass : normalClass}
                            >
                                {index + 1}
                            </a>
                        ))}
                </div>
            </div>
        </section>
    );
}

export default SecFiling;
