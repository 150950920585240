import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';

const SecondWithTabSection = ({ data, page }) => {
	const [show, setShow] = useState(false);

	return (
		<>
			<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-5'>
				<div
					style={{ backgroundImage: `url(\'${data.bgImgUrl}\')` }}
					className='w-full flex justify-center py-10 px-12 sm:px-20 md:px-28 lg:px-36'
				>
					<div className='min-w-full bg-white py-8 px-4 shadow sm:px-10'>
						<div>{data.teaser}</div>
						<div className='mt-3'>
							<div className='relative'>
								<div className='absolute inset-0 flex items-center'>
									<div className='w-full border-t border-gray-300' />
								</div>
								<div className='relative flex justify-center text-sm'>
									<span className='px-2 bg-white text-gray-400 text-lg'>
										<FontAwesomeIcon icon={faSnowflake} />
									</span>
								</div>
							</div>
						</div>
						<h2 className='mt-3 flex flex-col items-center md:flex-row md:justify-center font-semibold'>
							<a
								href='/technology-ultc'
								className={`${
									page === 'ULTC' ? 'text-gray-400' : 'text-primary-blue'
								} block`}
							>
								{data.title}
							</a>
							<span className='md:block mx-3 hidden'>{'|'}</span>
							<a
								href='/technology-pfca'
								className={`${
									page === 'PFCA' ? 'text-gray-400' : 'text-primary-blue'
								} block mt-1 md:mt-0`}
							>
								{data.title1}
							</a>
						</h2>

						{page === 'ULTC' && (
							<>
								<div className='mt-3'>
									<a href={data.videoUrl || '#'} target='_blank'>
										<img src={data.imgUrl} alt='' />
									</a>
								</div>
								<div className='mt-3'>
									<div
										dangerouslySetInnerHTML={{
											__html: data.body,
										}}
									></div>
									<p className='mt-3 font-medium text-primary-blue'>
										<a
											href='#'
											onClick={(e) => {
												e.preventDefault();
												setShow(!show);
											}}
										>
											{!show ? 'Show more...' : 'Show less'}
										</a>
									</p>
								</div>
							</>
						)}

						{page === 'PFCA' && (
							<>
								<div className='mt-3'>
									<a href={data.video1Url || '#'} target='_blank'>
										<img src={data.img1Url} alt='' />
									</a>
								</div>
								<div className='mt-3'>
									<div
										dangerouslySetInnerHTML={{
											__html: data.body1,
										}}
									></div>
									<p className='mt-3 font-medium text-primary-blue'>
										<a
											href='#'
											onClick={(e) => {
												e.preventDefault();
												setShow(!show);
											}}
										>
											{!show ? 'Show more...' : 'Show less'}
										</a>
									</p>
								</div>
							</>
						)}
					</div>
				</div>
			</section>

			{show && (
				<>
					{page === 'ULTC' && (
						<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 pb-5'>
							<div className='w-full flex justify-center py-10'>
								<div className='min-w-full pt-8 px-6 sm:px-8 md:px-10 lg:px-12'>
									<h2 className='text-center font-semibold text-primary-blue'>
										{data.detailsTitle}
									</h2>
									<div className='mt-3'>
										<div className='relative'>
											<div className='absolute inset-0 flex items-center'>
												<div className='w-full border-t border-gray-300' />
											</div>
											<div className='relative flex justify-center text-sm'>
												<span className='px-2 bg-gray-100 text-gray-400 text-lg'>
													<FontAwesomeIcon icon={faSnowflake} />
												</span>
											</div>
										</div>
									</div>
									<div className='sm:flex justify-center mt-3'>
										<div className='mb-4 sm:min-w-1/2 sm:w-1/2 flex justify-center sm:mb-0 sm:mr-4'>
											<img src={data.detailsImgUrl} alt='' />
										</div>
										<div className='sm:min-w-1/2 sm:w-1/2 sm:mt-10 px-6 lg:px-8'>
											<div
												dangerouslySetInnerHTML={{
													__html: data.detailsList,
												}}
											></div>
										</div>
									</div>
								</div>
							</div>
							<h4 className='text-lg font-bold text-center'>
								{data.detailsBottom}
							</h4>
						</section>
					)}

					{page === 'PFCA' && (
						<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 pb-5'>
							<div className='w-full flex justify-center py-10'>
								<div className='min-w-full pt-8 px-6 sm:px-8 md:px-10 lg:px-12'>
									<h2 className='text-center font-semibold text-primary-blue'>
										{data.detailsTitle1}
									</h2>
									<div className='mt-3'>
										<div className='relative'>
											<div className='absolute inset-0 flex items-center'>
												<div className='w-full border-t border-gray-300' />
											</div>
											<div className='relative flex justify-center text-sm'>
												<span className='px-2 bg-gray-100 text-gray-400 text-lg'>
													<FontAwesomeIcon icon={faSnowflake} />
												</span>
											</div>
										</div>
									</div>
									<div className='sm:flex justify-center mt-3'>
										<div className='mb-4 sm:min-w-1/2 sm:w-1/2 flex justify-center sm:mb-0 sm:mr-4'>
											<img src={data.detailsImg1Url} alt='' />
										</div>
										<div className='sm:min-w-1/2 sm:w-1/2 sm:mt-10 px-6 lg:px-8'>
											<div
												dangerouslySetInnerHTML={{
													__html: data.detailsList1,
												}}
											></div>
										</div>
									</div>
								</div>
							</div>
							<h4 className='text-lg font-bold text-center'>
								{data.detailsBottom1}
							</h4>
						</section>
					)}
				</>
			)}
		</>
	);
};

export default SecondWithTabSection;
