import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Hero from '../components/hero';
import SecondPostSection from '../components/second-post-section';
import { getPage } from '../helpers/api-utils';

const PressReleasesDetails = () => {
	const params = useParams();
	const [data, setData] = useState(null);

	useEffect(() => {
		const apiPath = 'press-releases-pages';
		const resObjName = 'pressReleasesPages';
		getPage(apiPath, resObjName, setData);
	}, []);

	return (
		<>
			{data && (
				<>
					<Hero data={data.hero} />
					<SecondPostSection data={data.second} id={params.id} />
				</>
			)}
		</>
	);
};

export default PressReleasesDetails;
