import { faSnowflake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { getBoardOfDirectors } from "../../helpers/api-utils";
import MemberDetails from "./MemberDetails";

const BoardOfDirectorSection = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [idx, setIdx] = useState(null);
  const [res, setRes] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await getBoardOfDirectors();
      setRes(response);
    }

    fetchData();
  }, [])

  return (
    <>
      { open && (
        <MemberDetails open={open} setOpen={setOpen} data={res} idx={idx} />
      )}
      {res && (
        <section className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-5 pb-16' id="boardOfDirectors">
          <div className='w-full flex justify-center py-10 px-12 sm:px-20 md:px-28 lg:px-36'>
            <div className='min-w-full py-8 px-4 sm:px-10'>
              <h2 className='text-center font-semibold text-primary-blue'>
                {data?.boardTitle}
              </h2>
              <div className='mt-3'>
                <div className='relative'>
                  <div className='absolute inset-0 flex items-center'>
                    <div className='w-full border-t border-gray-300' />
                  </div>
                  <div className='relative flex justify-center text-sm'>
                    <span className='px-2 bg-gray-100 text-gray-400 text-lg'>
                      <FontAwesomeIcon icon={faSnowflake} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-5 md:gap-10 md:grid-cols-4 w-3/4 mx-auto'>
            {res.boards.map((item, index) => (
              <div className='flex flex-col items-center'>
                <div
                  className='col-span-1 mb-12 md:mb-0'
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(true);
                    setIdx(index);
                  }}
                >
                  <img
                    src={item?.imageUrl}
                    alt={item?.fullname}
                    className='w-full h-full object-center object-cover cursor-pointer'
                  />
                </div>
                <div className='mt-3 text-primary-blue font-bold text-center'>
                  {item?.fullname}
                </div>
                <div className='text-center border-b-4 border-secondary-blue text-primary-blue font-light'>
                  {item?.title}
                </div>
              </div>
            ))}
          </div>
        </section>
		  )}
    </>
  )
}

export default BoardOfDirectorSection;
