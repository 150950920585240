import { useEffect, useState } from "react"
import Spinner from "../components/spinner";
import { getFaq } from "../helpers/api-utils";
import Hero from "../components/hero/faq-hero";
import SecondFaqSection from "../components/second-faq-section";

export default function Faq({ region }) {
    const [data, setData] = useState(null);

    useEffect(() => {
        const apiPath = 'faqs-pages';
        const resObjName = 'faqPages';
        getFaq(apiPath, resObjName, setData, region);
    }, []);

    return (
        <>
            {data ? (
                <>
                    <Hero data={data.faqPages[0].hero} />
                    <SecondFaqSection data={data.questions} />
                </>
            ) : 
            (
                <Spinner />
            )}
        </>
    )
}
