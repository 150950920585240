import React, { useState, useEffect } from 'react';
import Hero from '../components/hero';
import SecondProductsSection from '../components/second-products-section';
import FourCellSection from '../components/four-cell-section';
import { getPageId } from '../helpers/api-utils';
import BottomProductsSection from '../components/bottom-products-section';
import Spinner from '../components/spinner';

let EU_URL = process.env.REACT_APP_LIVE_EU_URL;
if (process.env.NODE_ENV === 'development') {
	EU_URL = process.env.REACT_APP_LOCAL_EU_URL;
}

export default function ProductsIclas() {
	let pathname = 'products-iclas/657c0b07e16ab33e6d1ed3bd';
	if (window.location.href.includes(EU_URL)) {
		pathname = 'products-iclas/657c0ae0e16ab33e6d1ed3bc';
	}

	const [data, setData] = useState(null);

	useEffect(() => {
		const resObjName = 'productsPage';
		getPageId(pathname, resObjName, setData);
	}, []);
	return (
		<>
			{data ? (
				<>
					<Hero data={data.hero} />
					<SecondProductsSection data={data.second} />
					<FourCellSection data={data.fourcells} />
					<BottomProductsSection data={data.bottom} />
				</>
			) : (
				<Spinner />
			)}
		</>
	);
}
