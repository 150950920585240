import React from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import logger from 'redux-logger';
import rootReducer from './slices';

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => {
		if (process.env.NODE_ENV === 'development') {
			return getDefaultMiddleware().concat(logger);
		}

		return getDefaultMiddleware();
	},
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
	return <Provider store={store}>{props.children}</Provider>;
};
