import { useEffect, useState } from "react";
import { getPrivacyPolicy } from "../helpers/api-utils";
import "react-quill/dist/quill.snow.css";

const PrivacyPolicyModal = ({ showModal, closeModal }) => {
  let pathname = 'privacy-policy';
  const [data, setData] = useState(null);

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  useEffect(() => {
    const resObjName = 'privacyPolicies';
    getPrivacyPolicy(pathname, resObjName, setData)
  }, []);

  return (
    <>
      {showModal && (
        <div 
          onClick={handleOverlayClick} className="fixed inset-0 bg-gray-600 bg-opacity-75 flex pt-24 items-start justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-3/4 md:w-3/4 relative">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="text-2xl font-bold text-sky-500 mb-4">
              { data && data.title }
            </h2>
            <div className="overflow-y-auto max-h-96">
              <div className="text-gray-700 ql-editor"
                dangerouslySetInnerHTML={{
                  __html: data && data.body,
                }}
              >
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyPolicyModal;
