import React from 'react';
import { signIn } from '../../../store/slices/authSlice';
import FormSubmit from '../FormSubmit';
import Fields from './Fields';

const getReqBody = (values) => {
	return {
		email: values.email,
		password: values.password,
	};
};
const buttonLabel = 'Sign in';
const redirectPath = '/';

const SignInForm = ({ setRedirectPath }) => {
	return (
		<FormSubmit
			component={Fields}
			getReqBody={getReqBody}
			submitAction={signIn}
			buttonLabel={buttonLabel}
			redirectPath={redirectPath}
			setRedirectPath={setRedirectPath}
		/>
	);
};

export default SignInForm;
