import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import {
  FieldSet,
  Subtitle,
  TitleSet,
  Select,
  Card,
  Input,
  Checkbox,
  Radio,
  FORM_GRID,
} from '../inputs';
import { CountryCodeList } from './data';

export default function Fields({ values }) {
  const [newsletterOnly, setNewsletterOnly] = useState(false);
  const [professionalAccess, setProfessionalAccess] = useState(false);
  const [newsletterAndProfessionalAccess, setNewsletterAndProfessionalAccess] = useState(false);

	const handleCheckboxChange = (setter) => (event) => {
		const name = event.target.name;
		if (name === "newsletterOnly") {
			if (newsletterOnly === true) {
				setNewsletterOnly(false);
			} else {
				setNewsletterOnly(true);
			}
		} else if (name === "professionalAccess") {
			if (professionalAccess === true) {
				setProfessionalAccess(false);
			} else {
				setProfessionalAccess(true);
			}
		} else if (name === "newsletterAndProfessionalAccess") {
			if (newsletterAndProfessionalAccess === true) {
				setNewsletterAndProfessionalAccess(false);
			} else {
				setNewsletterAndProfessionalAccess(true);
			}
		}
  };

  useEffect(() => {
    if (newsletterAndProfessionalAccess === true) {
      setNewsletterOnly(false);
      setProfessionalAccess(false);
    }
		if (newsletterOnly === true) {
			setProfessionalAccess(false);
			setNewsletterAndProfessionalAccess(false);
		} 
		if (professionalAccess === true) {
			setNewsletterOnly(false);
			setNewsletterAndProfessionalAccess(false);
		}
  }, [newsletterOnly, professionalAccess, newsletterAndProfessionalAccess]);

  return (
    <>
      <FieldSet>
        <TitleSet>
          <Subtitle>News updates</Subtitle>
        </TitleSet>
        <div className='mt-5 md:mt-0 md:col-span-2'>
          <div className='flex flex-col space-y-4 mb-8'>
            <p className='font-bold'>Please choose the type of access and information you would like:</p>
            <div className='flex justify-start space-x-8'>
              <Field name="newsletterOnly" type="checkbox">
                {({ input, meta }) => (
                  <Checkbox
                    input={input}
                    meta={meta}
                    size={FORM_GRID[0]}
                    disabled={false}
                    required={false}
										checked={newsletterOnly}
                    onChange={handleCheckboxChange(setNewsletterOnly)}
                  >
                    Newsletter only
                  </Checkbox>
                )}
              </Field>
              <Field name="professionalAccess" type="checkbox">
                {({ input, meta }) => (
                  <Checkbox
                    input={input}
                    meta={meta}
                    label="Professional Access"
										checked={professionalAccess}
                    onChange={handleCheckboxChange(setProfessionalAccess)}
                  >
                    Professional Access
                  </Checkbox>
                )}
              </Field>
              <Field name="newsletterAndProfessionalAccess" type="checkbox">
                {({ input, meta }) => (
                  <Checkbox
                    input={input}
                    meta={meta}
										checked={newsletterAndProfessionalAccess}
                    label="Newsletter & Professional Access"
                    onChange={handleCheckboxChange(setNewsletterAndProfessionalAccess)}
                  >
                    Newsletter & Professional Access
                  </Checkbox>
                )}
              </Field>
            </div>
          </div>

          <p className='font-bold'>Enter email for Newsletter only - Scroll down to click submit</p>
          <Card>
            <Field name='email_update'>
              {({ input, meta }) => (
                <Input
                  input={input}
                  meta={meta}
                  label='Email *'
                  type='email'
                  size={FORM_GRID[0]}
                  placeholder=''
                  autoComplete='off'
                  required={newsletterOnly === true || newsletterAndProfessionalAccess === true}
                  disabled={!newsletterOnly && !newsletterAndProfessionalAccess}
                ></Input>
              )}
            </Field>
            <Field name='receiving_single' type='radio' value={'standard'}>
              {({ input, meta }) => (
                <Radio
                  input={input}
                  meta={meta}
                  size={FORM_GRID[0]}
                  label={'Save and Unpublish'}
                  disabled={!newsletterOnly && !newsletterAndProfessionalAccess}
                >
                  Yes, I agree to receive the updates on new educational content posted by Adagio Medical,
                  as well as Adagio Medical news, scientific and general product updates to the e-mail
                  address provided above. Consent to the mailing can be withdrawn at any time
                  (by e-mail to web_request@adagiomedical.com or to the contact details given in the legal notice).
                  The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal.
                </Radio>
              )}
            </Field>
          </Card>
        </div>
      </FieldSet>

      <FieldSet>
        <TitleSet>
          <Subtitle>Professional information</Subtitle>
        </TitleSet>
        <Card>
          <Field name='title'>
            {({ input, meta }) => (
              <Select
                input={input}
                meta={meta}
                label='Title'
                size={FORM_GRID[2]}
                required={false}
                disabled={!professionalAccess && !newsletterAndProfessionalAccess}
              >
                <option value=''>Select one</option>
                <option value='MD'>MD</option>
                <option value='PhD'>PhD</option>
                <option value='MPH'>MPH</option>
                <option value='RN'>RN</option>
                <option value='MSN'>MSN</option>
              </Select>
            )}
          </Field>
          <Field name='firstName'>
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label='First name *'
                type='text'
                size={FORM_GRID[2]}
                placeholder=''
                autoComplete='off'
                required={true}
                disabled={!professionalAccess && !newsletterAndProfessionalAccess}
              ></Input>
            )}
          </Field>
          <Field name='lastName'>
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label='Last name *'
                type='text'
                size={FORM_GRID[2]}
                placeholder=''
                autoComplete='off'
                required={true}
                disabled={!professionalAccess && !newsletterAndProfessionalAccess}
              ></Input>
            )}
          </Field>
          <Field name='email'>
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label='Email *'
                type='email'
                size={FORM_GRID[0]}
                placeholder=''
                autoComplete='off'
                required={true}
                disabled={!professionalAccess && !newsletterAndProfessionalAccess}
              ></Input>
            )}
          </Field>
          <Field name='professionalCat'>
            {({ input, meta }) => (
              <Select
                input={input}
                meta={meta}
                label='Professional category *'
                size={FORM_GRID[3]}
                required={professionalAccess === true || newsletterAndProfessionalAccess === true}
                disabled={!professionalAccess && !newsletterAndProfessionalAccess}
              >
                <option value=''>Select one</option>
                <option value='Physician'>Physician</option>
                <option value='Allied Professional'>Allied Professional</option>
                <option value='Healthcare Administrator'>
                  Healthcare Administrator
                </option>
                <option value='Patient or Caregiver'>
                  Patient or Caregiver
                </option>
                <option value='Industry Affiliate'>Industry Affiliate</option>
                <option value='Media and Communications'>
                  Media and Communications
                </option>
                <option value='Investor'>Investor</option>
                <option value='Other'>Other</option>
              </Select>
            )}
          </Field>
          <Field name='primarySpecialty'>
            {({ input, meta }) => (
              <Select
                input={input}
                meta={meta}
                label='Primary specialty *'
                size={FORM_GRID[3]}
                required={professionalAccess === true || newsletterAndProfessionalAccess === true}
                disabled={!professionalAccess && !newsletterAndProfessionalAccess}
              >
                <option value=''>Select one</option>
                <option value='Electrophysiology'>Electrophysiology</option>
                <option value='Cardiology'>Cardiology</option>
                <option value='Other Medical'>Other Medical</option>
                <option value='Non-medical'>Non-medical</option>
              </Select>
            )}
          </Field>
          <Field name='primaryHospital'>
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label='Primary affiliation/employer *'
                type='text'
                size={FORM_GRID[3]}
                placeholder=''
                autoComplete='off'
                required={true}
                disabled={!professionalAccess && !newsletterAndProfessionalAccess}
              ></Input>
            )}
          </Field>
          <Field name='countryCode'>
            {({ input, meta }) => (
              <Select
                input={input}
                meta={meta}
                label='Country *'
                size={FORM_GRID[3]}
                required={professionalAccess === true || newsletterAndProfessionalAccess === true}
                disabled={!professionalAccess && !newsletterAndProfessionalAccess}
              >
                <option value=''>Select a country</option>
                {Object.entries(CountryCodeList).map((country) => (
                  <option value={country[0]} key={country[0]}>{country[1]}</option>
                ))}
              </Select>
            )}
          </Field>
          <Field name='city'>
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label='City *'
                type='text'
                size={FORM_GRID[2]}
                placeholder=''
                autoComplete='off'
                required={true}
                disabled={!professionalAccess && !newsletterAndProfessionalAccess}
              ></Input>
            )}
          </Field>
          <Field name='state'>
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label='State/Province *'
                type='text'
                size={FORM_GRID[2]}
                placeholder=''
                autoComplete='off'
                required={true}
                disabled={!professionalAccess && !newsletterAndProfessionalAccess}
              ></Input>
            )}
          </Field>
          <Field name='postalCode'>
            {({ input, meta }) => (
              <Input
                input={input}
                meta={meta}
                label='Zip/Postal Code *'
                type='text'
                size={FORM_GRID[2]}
                placeholder=''
                autoComplete='off'
                required={true}
                disabled={!professionalAccess && !newsletterAndProfessionalAccess}
              ></Input>
            )}
          </Field>
        </Card>
      </FieldSet>

      <FieldSet>
        <TitleSet>
        </TitleSet>
        <Card>
          <Field name='receiving' type='radio' value={'standard'}>
            {({ input, meta }) => (
              <Radio
                input={input}
                meta={meta}
                size={FORM_GRID[0]}
                label={'Save and Unpublish'}
                disabled={!professionalAccess && !newsletterAndProfessionalAccess}
              >
                Yes, I agree to receive the updates on new educational content posted by Adagio Medical,
                as well as Adagio Medical news, scientific and general product updates to the e-mail
                address provided above. Consent to the mailing can be withdrawn at any time
                (by e-mail to web_request@adagiomedical.com or to the contact details given in the legal notice).
                The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal.
              </Radio>
            )}
          </Field>
          <Field name='receiving' type='radio' value={'advance'}>
            {({ input, meta }) => (
              <Radio
                input={input}
                meta={meta}
                size={FORM_GRID[0]}
                label={'Save and Unpublish'}
                disabled={!professionalAccess && !newsletterAndProfessionalAccess}
              >
                I would like to receive the access to in-depth technical information on company products.
                I understand that such information is intended for qualified medical professionals and
                the access to such information is restricted based on the laws and regulations in the country of my residence.
                I understand that Adagio Medical retains the right to restrict the access to such information based on
                the information I provided, in particular if I do not meet the access requirements.
                You can find more information at our <a className='text-primary-blue' href='/privacy-policy' target='_blank'>privacy policy</a>
              </Radio>
            )}
          </Field>
        </Card>
      </FieldSet>
    </>
  );
}
