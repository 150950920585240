import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';
import { getPost } from '../../helpers/api-utils';

const SecondPostSection = ({ data, id }) => {
	const [post, setPost] = useState(null);

	useEffect(() => {
		async function fetchData() {
			const response = await getPost(id);

			setPost(response.post);
		}

		fetchData();
	}, []);
	return (
		<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-5'>
			<div
				style={{ backgroundImage: `url(\'${data.bgImgUrl}\')` }}
				className='w-full flex justify-center py-10 px-12 sm:px-20 md:px-28 lg:px-36'
			>
				{post && (
					<div className='min-w-full bg-white py-8 px-4 shadow sm:px-10'>
						<h2 className='text-center font-semibold text-primary-blue'>
							{post.title}
						</h2>
						<div className='mt-3'>
							<div className='relative'>
								<div className='absolute inset-0 flex items-center'>
									<div className='w-full border-t border-gray-300' />
								</div>
								<div className='relative flex justify-center text-sm'>
									<span className='px-2 bg-white text-gray-400 text-lg'>
										<FontAwesomeIcon icon={faSnowflake} />
									</span>
								</div>
							</div>
						</div>
						<div className='mt-3'>
							<div
								dangerouslySetInnerHTML={{
									__html: post.body,
								}}
							></div>
						</div>
						<p className='text-right w-full mt-5'>
							<Link
								to={`/press-releases`}
								className='px-3 py-1 bg-primary-blue border border-primary-blue text-sm text-white rounded-md shadow-sm hover:bg-opacity-60 sm:px-5'
							>
								Back
							</Link>
						</p>
					</div>
				)}
			</div>
		</section>
	);
};

export default SecondPostSection;
