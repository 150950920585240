import { authForFirebaseBackend } from '../../firebase';
import axios from 'axios';
import {
	signInWithEmailAndPassword,
	signOut as firebaseSignOut,
	sendPasswordResetEmail,
} from 'firebase/auth';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	currentUser: null,
};

let EU_URL = process.env.REACT_APP_LIVE_EU_URL;
if (process.env.NODE_ENV === 'development') {
	EU_URL = process.env.REACT_APP_LOCAL_EU_URL;
}

let BASE_API_URL = process.env.REACT_APP_LIVE_API_URL;

if (process.env.NODE_ENV === 'development') {
	BASE_API_URL = process.env.REACT_APP_LOCAL_API_URL;
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		updateCurrentUser: (state, action) => {
			state.currentUser = action.payload;
		},
	},
});

export const { updateCurrentUser } = authSlice.actions;

export const signIn =
	({ email, password }, callback) =>
	async (dispatch) => {
		try {
			const userAuth = await signInWithEmailAndPassword(authForFirebaseBackend, email, password);
			localStorage.setItem('accessToken', userAuth.user.accessToken);
			localStorage.setItem('user', JSON.stringify({'displayName': userAuth.user.displayName, 'uid': userAuth.user.uid, 'email': userAuth.user.email}))

			dispatch(updateCurrentUser(userAuth.user.displayName));
			callback();
		} catch (err) {
			callback(err.message);
		}
	};

export const signOut = () => async (dispatch) => {
	try {
		await firebaseSignOut(authForFirebaseBackend);
		localStorage.removeItem('accessToken');
		localStorage.removeItem('user');

		dispatch(updateCurrentUser(null));
	} catch (err) {
		console.log(err.message);
	}
};

export const sendRegistation = (reqBody, callback, values) => (dispatch) => {
	axios
		.post(BASE_API_URL + 'users/adagio', reqBody)
		.then((res) => {
			callback(null, values.email);
		})
		.catch((err) => {
			if (err.message === "Request failed with status code 409") {
				callback("User with this email already exists");
			} else {
				callback(err.message);
			}
		});
};

export const sendContact = (reqBody, callback, values) => (dispatch) => {
	let region = 'contact';
	if (window.location.href.includes(EU_URL)) {
		region = 'contact-eu';
	}
	axios
		.post(BASE_API_URL + `email/${region}`, reqBody)
		.then((res) => {
			callback(null, 'success');
		})
		.catch((err) => {
			callback(err.message);
		});
};

export const sendApply = (reqBody, callback, values) => (dispatch) => {
	axios
		.post(BASE_API_URL + 'email/apply', reqBody)
		.then((res) => {
			callback(null, 'success');
		})
		.catch((err) => {
			callback(err.message);
		});
};

export const resetPassword =
	({ email }, callback) =>
	async () => {
		try {
			await sendPasswordResetEmail(authForFirebaseBackend, email);
			callback(null, email);
		} catch (err) {
			callback(null, err.message);
		}
	};

export default authSlice.reducer;
