import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnowflake } from '@fortawesome/free-solid-svg-icons';
import PressReleasesList from './PressReleasesList';

const SecondPressReleasesSection = ({ data }) => {
	const [tab, setTab] = useState('new');

	return (
		<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-5'>
			<div
				style={{ backgroundImage: `url(\'${data.bgImgUrl}\')` }}
				className='w-full flex justify-center py-10 px-12 sm:px-20 md:px-28 lg:px-36'
			>
				<div className='min-w-full bg-white py-8 px-4 shadow sm:px-10'>
					<h2 className='mt-3 flex flex-col items-center md:flex-row md:justify-center font-semibold'>
						<a
							href='#'
							onClick={(e) => {
								e.preventDefault();
								setTab('new');
							}}
							className={`${
								tab === 'new' ? 'text-gray-400' : 'text-primary-blue'
							} block`}
						>
							LATEST PRESS RELEASES
						</a>
						<span className='md:block mx-3 hidden'>{'|'}</span>
						<a
							href='#'
							onClick={(e) => {
								e.preventDefault();
								setTab('old');
							}}
							className={`${
								tab === 'old' ? 'text-gray-400' : 'text-primary-blue'
							} block mt-1 md:mt-0`}
						>
							OLDER PRESS RELEASES
						</a>
					</h2>
					<div className='mt-3'>
						<div className='relative'>
							<div className='absolute inset-0 flex items-center'>
								<div className='w-full border-t border-gray-300' />
							</div>
							<div className='relative flex justify-center text-sm'>
								<span className='px-2 bg-white text-gray-400 text-lg'>
									<FontAwesomeIcon icon={faSnowflake} />
								</span>
							</div>
						</div>
					</div>
					<PressReleasesList category={tab} />
				</div>
			</div>
		</section>
	);
};

export default SecondPressReleasesSection;
