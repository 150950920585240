import { useEffect, useRef } from "react";

const LegalNoticePopup = ({ showPopup, handleClosePopup }) => {
  const popupRef = useRef(null);

  useEffect(() => {
		const handleClickOutside = (event) => {
			if (popupRef.current && !popupRef.current.contains(event.target)) {
				handleClosePopup();
			}
		};

		if (showPopup) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [showPopup, handleClosePopup]);

	return (
		<>
      {
        showPopup && (
          <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
            <div className="relative p-8 bg-white w-full max-w-3xl m-auto flex-col flex shadow-md">
              <button
                className="absolute top-0 right-0 p-2 text-gray-600 hover:text-gray-800"
                onClick={handleClosePopup}
              >
                &times;
              </button>
              <h2 className="text-2xl font-bold mb-4 text-center">Legal Notice</h2>
              <div className="my-8">
                <p className="font-bold">Adagio Medical Inc.</p>
                <p className="font-bold">26051 Merit Cir #102, Laguna Hills, CA 92653.</p>
              </div>
              <div>
                <p>Represented by the directors</p>
                <ul className="list-disc list-inside">
                  <li>Olav Bergheim</li>
                  <li>James Cox</li>
                  <li>Timothy P. Moran</li>
                  <li>Shahram Moaddeb</li>
                  <li>Orly Mishan</li>
                  <li>Sandra Gardiner</li>
                  <li>Keyvan Mirsaeedi-Farahani</li>
                </ul>
              </div>
              <div className="my-4">
                <p>Phone: +(1) (949) 348-1188</p>
                <p>Email: <a href="mailto:web_request_eu@adagiomedical.com" className="text-blue-600">web_request_eu@adagiomedical.com</a></p>
              </div>
              <p>Commercial register number: Amtsgericht München HRB 253396</p>
              <p>VAT ID: DE329789884</p>
            </div>
          </div>
        )
      }
    </>
	);
}

export default LegalNoticePopup;
