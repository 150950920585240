import React from 'react';
import { Link } from 'react-router-dom';

export const ALink = ({ href, children, ...props }) => {
	return (
		<a
			href={href}
			{...props}
			className='text-primary-blue decoration-blue-300 hover:decoration-blue-400 hover:text-blue-400 hover:text-primary-blue'
		>
			{children}
		</a>
	);
};

const RouterLink = ({ path, children, ...props }) => {
	return (
		<Link
			{...props}
			to={path}
			className='font-medium text-primary-blue hover:text-sky-500'
		>
			{children}
		</Link>
	);
};

export default RouterLink;
