import { Link } from 'react-router-dom';
const BUTTON_TEXT = 'EXPLORE...';

const Hero = ({ data }) => {
	return (
		<section id='hero'>
			{/* Hero section */}

			<div className='relative'>
				<div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
					<div className='relative sm:overflow-hidden'>
						<div className='absolute inset-0'>
							<img
								className='h-full w-full object-cover'
								src={data.imageUrl}
								alt='People working on laptops'
							/>
							<div className='absolute inset-0' />
						</div>
						<div className='relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8'>
							<h1 className='text-center text-3xl font-medium tracking-tight sm:text-4xl lg:text-5xl'>
								<span className='block text-white'>{data.title}</span>
							</h1>
							{data.explorePath && (
								<div className='mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center'>
									<Link
										to={data.explorePath}
										className='flex items-center justify-center px-4 py-3 bg-transparent border border-white text-sm rounded-md shadow-sm text-white hover:bg-primary-blue hover:bg-opacity-60 sm:px-8'
									>
										{BUTTON_TEXT}
									</Link>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
