// dependencies
import React, { useState, useEffect } from 'react';
import Router from './router';
import { onAuthStateChanged } from 'firebase/auth';
// components
import { auth } from './firebase';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentUser } from './store/slices/authSlice';
import './App.css';
// icon
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'

library.add(fas, faTwitter, faFontAwesome)

const App = () => {
	const [isAuthChecked, setIsAuthChecked] = useState(false);
	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.auth.currentUser);

	useEffect(() => {
		onAuthStateChanged(auth, (_user) => {
			if (process.env.NODE_ENV === 'development') {
				console.log('User state changed. Current user is: ', _user);
			}
			if (_user) {
				dispatch(updateCurrentUser(_user.displayName));
			}
			setIsAuthChecked(true);
		});
	});

	return <>{isAuthChecked && <Router currentUser={currentUser} />}</>;
};

export default App;
